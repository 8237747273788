import { UpdatesIcon } from '../icons';
import List from './List';

export default {
	options: {
		group: "manage",
		roles: ["admin", "user"],
	},
	icon: UpdatesIcon,
	list: List,
};
