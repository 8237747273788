import RemoteErrorsInteceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import { required, SimpleForm, TextInput } from "react-admin";
import { compose } from "recompose";
import Create from "../components/form/Create";

const BotCreate = ({ dispatch, validate, errors, ...props }) => {
	return (
		<Create {...props}>
			<SimpleForm variant="outlined" redirect="edit" validate={validate}>
				<RemoteErrorsInteceptor errors={errors} dispatch={dispatch} />
				<TextInput validate={required()} source="name" fullWidth />
				<TextInput validate={required()} source="username" fullWidth />
				<TextInput
					validate={required()}
					source="api_key"
					resettable
					fullWidth
				/>
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(BotCreate);
