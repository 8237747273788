import React from "react";

import { get } from "lodash";
import { ChipField, SelectInput, useTranslate } from "react-admin";

import { green, orange, red, yellow } from "@material-ui/core/colors";

export const statuses = [
	{ id: "pending", name: "pending", color: yellow[800] },
	{ id: "sent", name: "sent", color: green[800] },
	{ id: "error", name: "error", color: red[600] },
	{ id: "deleted", name: "deleted", color: red[600] },
	{ id: "deleting", name: "deleting", color: orange[600] },
	{ id: "sending", name: "sending", color: orange[600] },
	{ id: "pinning_to_top", name: "pinning_to_top", color: orange[600] },
	{ id: "pinned_to_top", name: "pinned_to_top", color: green[800] },
];

export const StatusField = ({ record, ...rest }) => {
	const translate = useTranslate();
	const label =
		statuses.find((s) => s.id === get(record, "status")) || statuses[0];
	return (
		<ChipField
			{...rest}
			record={{
				...label,
				name: translate(`resources.queues.fields.statuses.${label.name}`),
			}}
			source="name"
			style={{
				backgroundColor: get(label, "color", ""),
				color: "white",
			}}
		/>
	);
};

export const StatusInput = ({ record, source, ...rest }) => {
	const translate = useTranslate();
	return (
		<SelectInput
			{...rest}
			source={source}
			label="resources.queues.fields.status"
			choices={statuses.map((type) => ({
				...type,
				name: translate(`resources.queues.fields.statuses.${type.name}`),
			}))}
		/>
	);
};
