import React from 'react';

import {
  Button,
  useRedirect,
  useTranslate,
  useUnselectAll,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';

import { useBotContext } from '../../contexts/BotContext';

const useStyles = makeStyles((theme) => ({
	unselect: {
		color: orange[700],
	},
}));

const UnsetAllBotsButton = ({ selectedIds }) => {
	const classes = useStyles();
	const translate = useTranslate();
	const { toggleAllBots, bots } = useBotContext();
	const { search } = useLocation();
	const resource = new URLSearchParams(search).get("back");
	const unselectAll = useUnselectAll("bots");
	const redirect = useRedirect();

	const handleClick = () => {
		const _bots = Object.values(bots)
			.filter((bot) => !selectedIds.includes(bot.id))
			.reduce((acc, item) => {
				acc[item.id] = item;
				return acc;
			}, {});
		toggleAllBots(_bots);
		unselectAll();
		if (resource && resource !== "bots") {
			redirect(resource);
		}
	};

	return (
		<Button
			className={classes.unselect}
			label={translate("resources.bots.actions.unselect_this")}
			onClick={handleClick}
		/>
	);
};

export default UnsetAllBotsButton;
