import SendIcon from "@material-ui/icons/Send";
import { get } from "lodash";
import * as React from "react";
import { Button, Confirm, Toolbar } from "react-admin";
import { useFormState } from "react-final-form";

const QueueCreateToolbar = ({ handleSubmit, saving, ...props }) => {
	const [open, setOpen] = React.useState(false);
	const { values: formValues } = useFormState();
	const repeat = get(formValues, "repeat", false);

	const handleConfirm = () => {
		handleSubmit();
		setOpen(false);
	};

	const handleSave = () => {
		if (repeat) {
			setOpen(true);
		} else {
			handleConfirm();
		}
	};

	return (
		<Toolbar {...props}>
			<Confirm
				isOpen={open}
				loading={saving}
				title="resources.queues.create.confirm.title"
				content="resources.queues.create.confirm.description"
				onConfirm={handleConfirm}
				onClose={() => setOpen(false)}
			/>
			<Button
				variant="contained"
				label="ra.action.send"
				size="medium"
				onClick={handleSave}
				disabled={saving}
			>
				<SendIcon />
			</Button>
		</Toolbar>
	);
};

export default QueueCreateToolbar;
