import React from 'react';

import { TextField } from 'react-admin';

import { Link } from '@material-ui/core';

const BotUsernameField = (props) => {
	const username = props?.record?.[props?.source] || "";
	const link = "https://t.me/" + username;
	return (
		<Link href={link} target="_blank">
			<TextField
				{...props}
				style={{ fontWeight: "bold" }}
				record={{
					...props?.record,
					[props?.source]: "@".concat(username),
				}}
			/>
		</Link>
	);
};

export default BotUsernameField;
