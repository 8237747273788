import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	ArrayInput,
	CheckboxGroupInput,
	required,
	SelectInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
} from "react-admin";
import { compose } from "recompose";
import Edit from "../components/form/Edit";
import { components, rules } from "./index";

const MessageTypeEdit = ({ dispatch, validate, errors, ...props }) => (
	<Edit title="name" {...props}>
		<SimpleForm variant="outlined" validate={validate}>
			<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
			<TextInput validate={required()} source="name" fullWidth />
			<TextInput validate={required()} source="method" fullWidth />
			<ArrayInput validate={required()} source="message_type_fields">
				<SimpleFormIterator
					disableReordering
					TransitionProps={{
						enter: false,
					}}
				>
					<TextInput validate={required()} source="name" fullWidth />
					<TextInput source="default" fullWidth />
					<CheckboxGroupInput source="rules" choices={rules} fullWidth />
					<SelectInput
						fullWidth
						validate={required()}
						source="component"
						choices={components}
					/>
				</SimpleFormIterator>
			</ArrayInput>
		</SimpleForm>
	</Edit>
);
export default compose(withErrors)(MessageTypeEdit);
