import { useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { get } from "lodash";
import React from "react";
import { Error, Loading, TextInput, useQueryWithStore } from "react-admin";
import SwitchInput from "./SwitchInput";

const MessageFieldsInputView = ({ source, messageTypeId, ...props }) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const {
		data: messageType,
		loading,
		error,
	} = useQueryWithStore({
		type: "getOne",
		resource: "message_types",
		payload: { id: messageTypeId },
	});

	if (error) {
		return <Error />;
	}

	if (loading) {
		return <Loading />;
	}

	const fields = get(messageType, "message_type_fields", []);

	if (fields.length === 0) {
		return null;
	}

	return (
		<Grid container spacing={isSmall ? 0 : 1}>
			{fields.map((field, index) => (
				<Grid item md={12} xs={12} key={field.id}>
					<div style={{ display: "none" }}>
						<TextInput
							label={false}
							type="hidden"
							source={`${source}.[${index}].id`}
						/>
						<TextInput
							label={false}
							type="hidden"
							defaultValue={field.id}
							source={`${source}[${index}].message_type_field_id`}
						/>
					</div>
					<SwitchInput
						{...props}
						index={index}
						field={field}
						source={`${source}[${index}].value`}
					/>
				</Grid>
			))}
		</Grid>
	);
};

const MessageFieldsInput = ({ messageTypeId, ...props }) => {
	if (!messageTypeId) {
		return null;
	}
	return <MessageFieldsInputView messageTypeId={messageTypeId} {...props} />;
};

export default MessageFieldsInput;
