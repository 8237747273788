import React, { useEffect } from 'react';

import {
  Button,
  useGetList,
  useListContext,
  useRefresh,
  useTranslate,
  useUnselectAll,
  useUpdateMany,
} from 'react-admin';

import {
  Avatar,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/CheckCircle';
import UndoIcon from '@material-ui/icons/ErrorOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { FolderIcon } from '../../icons';

const useStyles = makeStyles((theme) => ({
	avatar: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
}));

const FoldersButton = ({ selectedIds }) => {
	const translate = useTranslate();
	const [open, setOpen] = React.useState(false);
	const classes = useStyles();
	const [localData, setLocalData] = React.useState({});
	const { data } = useListContext();
	const selectedValue = localData?.[selectedIds?.[0]];
	const selectedFolders = React.useMemo(() => {
		if (!selectedValue?.folders) {
			return {};
		}
		return selectedValue.folders.reduce((acc, folder) => {
			acc[folder.id] = true;
			return acc;
		}, {});
	}, [selectedValue]);
	const [selectedValues, setSelectedValues] = React.useState(selectedFolders);
	const [updateMany, { loading: updateLoading }] = useUpdateMany();
	const unselectAll = useUnselectAll("messages");
	const refresh = useRefresh();

	const { data: folders, loading } = useGetList(
		"folders",
		{ page: 1, perPage: 10 },
		{ field: "name", order: "ASC" },
		{ bot_ids: selectedValue?.bot_id }
	);

	useEffect(() => {
		setSelectedValues(selectedFolders);
	}, [selectedFolders]);

	useEffect(() => {
		setLocalData((d) => ({ ...d, ...data }));
	}, [data]);

	const handleUpdate = () => {
		updateMany(
			"messages",
			selectedIds,
			{
				folders: Object.keys(selectedValues)
					.filter((_id) => selectedValues[_id] === true)
					.map((_id) => ({
						id: _id,
					})),
			},
			{
				onSuccess: () => {
					setOpen(false);
					unselectAll();
					refresh();
				},
			}
		);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
	};

	if (
		selectedIds?.length === 0 ||
		selectedIds?.length > 1 ||
		!selectedValue ||
		Object.values(folders)?.length === 0
	) {
		return null;
	}

	return (
		<>
			<Button
				color="secondary"
				onClick={handleClickOpen}
				label={translate("resources.messages.fields.folder_ids")}
				disabled={loading}
			>
				<FolderIcon />
			</Button>
			<Dialog
				onClose={handleClose}
				aria-labelledby="simple-dialog-title"
				open={open}
			>
				<DialogTitle id="simple-dialog-title">
					{selectedValue?.name || ""}
				</DialogTitle>
				<List>
					{Object.values(folders).map((folder) => (
						<ListItem
							button
							onClick={() => {
								setSelectedValues((prev) => ({
									...prev,
									[folder.id]: prev[folder.id] === undefined ? true : undefined,
								}));
							}}
							key={folder.id}
							selected={selectedValues?.[folder.id] === true}
						>
							<ListItemAvatar>
								<Avatar
									style={{ backgroundColor: folder?.color }}
									className={classes.avatar}
								>
									<FiberManualRecordIcon style={{ color: folder?.color }} />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={folder?.name} />
							<ListItemSecondaryAction>
								<Checkbox
									edge="end"
									onChange={() => {
										setSelectedValues((prev) => ({
											...prev,
											[folder.id]:
												prev[folder.id] === undefined ? true : undefined,
										}));
									}}
									checked={selectedValues?.[folder.id] === true}
								/>
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</List>
				<DialogActions>
					<Button
						onClick={handleClose}
						disabled={updateLoading}
						label={translate("ra.action.undo")}
						color="default"
					>
						<UndoIcon />
					</Button>
					<Button
						onClick={handleUpdate}
						disabled={updateLoading}
						label={translate("resources.messages.actions.select_folders")}
					>
						<DoneIcon />
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default FoldersButton;
