import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import { SimpleForm } from "react-admin";
import { compose } from "recompose";
import Edit from "../components/form/Edit";
import Form from "./Form";
import Toolbar from "./Toolbar";

const MessageEdit = ({ dispatch, validate, errors, ...props }) => (
	<Edit {...props} title="name">
		<SimpleForm variant="outlined" validate={validate} toolbar={<Toolbar />}>
			<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
			<Form />
		</SimpleForm>
	</Edit>
);
export default compose(withErrors)(MessageEdit);
