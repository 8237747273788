import React from 'react';

import { TextInput } from 'react-admin';

import { DateTimeInput } from '../components/DatePicker';
import Filters from '../components/form/Filters';
import { StatusInput } from './Status';

const TargetFilters = (props) => {
	return (
		<Filters {...props} showSearch={false}>
			<TextInput
				source="chat"
				label="resources.updates.fields.data.chat.title"
				alwaysOn
			/>
			<TextInput
				source="payload"
				label="resources.updates.fields.payload"
				alwaysOn
			/>
			<DateTimeInput source="created_start" alwaysOn />
			<DateTimeInput source="created_end" alwaysOn />
			<TextInput source="type" alwaysOn />
			<StatusInput source="status" alwaysOn />
		</Filters>
	);
};
export default TargetFilters;
