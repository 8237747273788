let appUrl = `//${document.location.host}/`;
let environment = "PRODUCTION";
if (appUrl.endsWith(":3000/")) {
	// appUrl = "//tgfuel.ddev.site/";
	appUrl = "https://tgfuel.com/";
	environment = "DEVELOPER";
}
export const APP_URL = appUrl;
export const API_URL = `${APP_URL}api`;
export const VERSION = "2.0.0";
export const ENVIRONMENT = environment;
