import FileCopyIcon from "@material-ui/icons/FileCopy";
import { get } from "lodash";
import React, { useState } from "react";
import { Button, useDataProvider, useRedirect } from "react-admin";

const CloneButton = ({
	basePath = "",
	label = "ra.action.clone",
	record,
	...rest
}) => {
	const redirect = useRedirect();
	const dataProvider = useDataProvider();
	const [loading, setLoading] = useState(false);
	const clone = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setLoading(true);
		dataProvider.getOne("import_queues", { id: record.id }).then((response) => {
			const data = get(response, "data", {});
			const { id, created, modified, urls, ...record } = data;
			setLoading(false);
			redirect("create", "/import_queues", null, {}, { record });
		});
	};

	return (
		<Button label={label} color="secondary" onClick={clone} disabled={loading}>
			<FileCopyIcon />
		</Button>
	);
};

export default CloneButton;
