import React from 'react';

import {
  DateField,
  TextField,
} from 'react-admin';

import { useMediaQuery } from '@material-ui/core';

import Datagrid from '../components/lists/Datagrid';
import List from '../components/lists/List';
import BulkActionButtons from './BulkActionButtons';
import SetBotButton from './buttons/SetBotButton';
import BotUsernameField from './fields/BotUsernameField';
import Filters from './Filters';
import StatusField from './StatusField';

const BotList = (props) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	return (
		<List
			{...props}
			sort={{ field: "modified", order: "DESC" }}
			filters={<Filters />}
			bulkActionButtons={<BulkActionButtons />}
		>
			<Datagrid>
				<SetBotButton />
				{!isSmall && <TextField source="name" style={{ fontWeight: "bold" }} />}
				<BotUsernameField source="username" variant="caption" />
				{!isSmall && <StatusField source="status" />}
				{!isSmall && <DateField source="modified" showTime />}
			</Datagrid>
		</List>
	);
};
export default BotList;
