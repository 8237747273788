import { makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React from "react";
import { Datagrid as RaDatagrid, EditButton, TextField } from "react-admin";
import { useSelector } from "../../hooks/useStyles";

const getWidthToSubtract = (w) => {
	return w + (window.innerWidth - document.documentElement.clientWidth);
};

const useStyles = makeStyles((theme) => ({
	[theme.breakpoints.down("lg")]: {
		container: {
			maxWidth: `calc(100vw - ${theme.spacing(4)}px)`,
			overflowX: "auto",
		},
	},
	[theme.breakpoints.up("lg")]: {
		container: (props) => ({
			maxWidth: `calc(100vw - ${
				props.sidebarOpen ? getWidthToSubtract(288) : getWidthToSubtract(105)
			}px)`,
			overflowX: "auto",
		}),
	},
	rowEven: { backgroundColor: grey[100] },
}));

const Datagrid = ({
	children,
	showPrimaryKey = false,
	showEdit = true,
	...props
}) => {
	const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
	const classes = useStyles({ sidebarOpen });
	return (
		<div className={classes.container}>
			<RaDatagrid classes={{ rowEven: classes.rowEven }} {...props}>
				{showPrimaryKey && (
					<TextField
						source="id"
						label="ID"
						variant="overline"
						style={{ fontWeight: "bold" }}
					/>
				)}
				{children}
				{showEdit && <EditButton />}
			</RaDatagrid>
		</div>
	);
};

export default Datagrid;
