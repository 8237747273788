import React from 'react';

import moment from 'moment';
import {
  ChipField,
  DateField,
  EditButton,
  TextField,
} from 'react-admin';

import Datagrid from '../components/lists/Datagrid';
import ListBot from '../components/lists/ListBot';
import { useBotContext } from '../contexts/BotContext';
import Expand from './Expand';
import Filters from './Filters';
import { StatusField } from './Status';

const ListItems = (props) => {
	const { canShowBotIdField } = useBotContext();
	return (
		<Datagrid {...props} size="small" showEdit={false} expand={<Expand />}>
			<TextField
				source="data.chat.title"
				style={{ fontWeight: "bold" }}
				label="resources.updates.fields.data.chat.title"
			/>
			<TextField source="type" variant="overline" />
			<StatusField source="status" />
			{canShowBotIdField() && <ChipField source="bot.name" label="Bot" />}
			<DateField source="created" showTime />
			<EditButton />
		</Datagrid>
	);
};

const TargetList = (props) => (
	<ListBot
		{...props}
		sort={{ field: "created", order: "DESC" }}
		filters={<Filters />}
		filterDefaultValues={{
			created_start: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
			created_end: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
			type: "chat_join_request",
			status: "processed",
		}}
	>
		<ListItems />
	</ListBot>
);
export default TargetList;
