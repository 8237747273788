import React from 'react';

import RemoteErrorsInteceptor, { withErrors } from 'ra-remote-validator';
import {
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';
import { compose } from 'recompose';

import { InputAdornment } from '@material-ui/core';

import Edit from '../components/form/Edit';
import BotInput from '../components/inputs/BotInput';
import { types } from '../targets/Type';

const TargetEdit = ({ dispatch, validate, errors, ...props }) => {
	const translate = useTranslate();
	return (
		<Edit {...props} title="name">
			<SimpleForm variant="outlined" redirect="edit" validate={validate}>
				<RemoteErrorsInteceptor errors={errors} dispatch={dispatch} />
				<BotInput source="bot_id" validate={required()} fullWidth />
				<SelectInput source="type" choices={types} fullWidth disabled />
				<TextInput validate={required()} source="name" fullWidth />
				<TextInput validate={required()} source="telegram_id" fullWidth />
				<TextInput validate={required()} source="telegram_username" fullWidth />
				<TextInput source="telegram_firstname" fullWidth />
				<TextInput source="telegram_lastname" fullWidth />
				<FormDataConsumer>
					{({ formData, ...rest }) => {
						if (
							formData?.type !== "channel" &&
							formData?.type !== "supergroup"
						) {
							return null;
						}
						return (
							<>
								<BooleanInput
									{...rest}
									source="auto_accept_join_requests"
									fullWidth
									className=""
									helperText="resources.targets.fields.auto_accept_join_requests.help"
								/>
								{formData?.auto_accept_join_requests && (
									<NumberInput
										{...rest}
										source="auto_accept_join_requests_delay"
										fullWidth={false}
										className
										helperText="resources.targets.fields.auto_accept_join_requests_delay.help"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">min</InputAdornment>
											),
										}}
									/>
								)}
								<TextInput
									{...rest}
									source="chat_invite_link_password"
									fullWidth={true}
									className
									helperText={translate(
										"resources.targets.fields.chat_invite_link_password.help",
										{
											password:
												formData?.chat_invite_link_password || "$password",
										}
									)}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">/invite</InputAdornment>
										),
									}}
								/>
								{formData?.chat_invite_link_password && (
									<>
										<TextInput
											{...rest}
											source="chat_invite_link_name"
											fullWidth={true}
											className
											helperText="resources.targets.fields.chat_invite_link_name.help"
										/>
										<TextInput
											{...rest}
											source="chat_invite_link_member_limit"
											fullWidth={true}
											className
											helperText="resources.targets.fields.chat_invite_link_member_limit.help"
										/>
										<BooleanInput
											{...rest}
											source="chat_invite_link_creates_join_request"
											fullWidth={true}
											className
											helperText="resources.targets.fields.chat_invite_link_creates_join_request.help"
										/>
									</>
								)}
							</>
						);
					}}
				</FormDataConsumer>
			</SimpleForm>
		</Edit>
	);
};
export default compose(withErrors)(TargetEdit);
