import React from 'react';

import {
  ArrayField,
  BooleanField,
  ChipField,
  SingleFieldList,
  TextField,
} from 'react-admin';

import { useMediaQuery } from '@material-ui/core';

import Datagrid from '../components/lists/Datagrid';
import List from '../components/lists/List';
import Filters from './Filters';
import LoginButton from './LoginButton';

const UserList = (props) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	return (
		<List
			{...props}
			filters={<Filters />}
			sort={{ field: "id", order: "desc" }}
		>
			<Datagrid>
				<TextField source="username" style={{ fontWeight: "bold" }} />
				<BooleanField source="is_active" />
				{!isSmall && (
					<ArrayField source="roles">
						<SingleFieldList>
							<ChipField source="name" />
						</SingleFieldList>
					</ArrayField>
				)}
				<LoginButton />
			</Datagrid>
		</List>
	);
};

export default UserList;
