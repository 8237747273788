import React from 'react';

import {
  ChipField,
  TextField,
  useTranslate,
} from 'react-admin';

import Filters from '../components/form/Filters';
import Datagrid from '../components/lists/Datagrid';
import List from '../components/lists/List';
import FolderColorField from './fields/FolderColorField';

const FolderList = (props) => {
	const translate = useTranslate();
	return (
		<List
			{...props}
			sort={{ field: "name", order: "ASC" }}
			filters={<Filters />}
		>
			<Datagrid>
				<TextField source="name" style={{ fontWeight: "bold" }} />
				<FolderColorField source="color" sortable={false} />
				<ChipField
					source="bot.name"
					label="Bot"
					emptyText={translate("ra.boolean.all")}
				/>
			</Datagrid>
		</List>
	);
};
export default FolderList;
