import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useGetList } from 'react-admin';

import { useBotContext } from './BotContext';

const Folder = createContext({});

export const FolderContext = ({ children }) => {
	const { botIds } = useBotContext();
	// const authProfile = JSON.parse(localStorage.getItem("profile"));
	// const authProfileId = authProfile?.id;
	// const storageName = `messages.folder_id.${authProfileId}`;
	// const storedFolderId = parseInt(localStorage.getItem(storageName));
	const [folderId, _setFolderId] = useState(0);
	const [contextLoaded, setContextLoaded] = useState(false);

	useEffect(() => {
		setContextLoaded(true);
	}, []);

	const {
		data: folders,
		loading,
		loaded,
	} = useGetList(
		"folders",
		{ page: 1, perPage: 10 },
		{ field: "name", order: "ASC" },
		{ bot_ids: botIds.join(",") },
		{
			enabled: !contextLoaded,
		}
	);

	const setFolderId = useCallback(
		(_id) => {
			_setFolderId(_id);
			// localStorage.setItem(storageName, _id);
		},
		[_setFolderId]
	);

	const memoizedValue = useMemo(
		() => ({
			folderId,
			setFolderId,
			folders,
			loading,
			loaded,
		}),
		[folderId, setFolderId, folders, loading, loaded]
	);
	return <Folder.Provider value={memoizedValue}>{children}</Folder.Provider>;
};

export const useFolderContext = () => useContext(Folder);
