import React from 'react';

import {
  ChipField,
  DateField,
  NumberField,
  SelectField,
  TextField,
} from 'react-admin';

import { useMediaQuery } from '@material-ui/core';

import Datagrid from '../components/lists/Datagrid';
import ListBot from '../components/lists/ListBot';
import { useBotContext } from '../contexts/BotContext';
import actionTypes from './action_types';
import Expand from './Expand';
import CommandField from './fields/CommandField';
import Filters from './Filters';

const ListItems = (props) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const { canShowBotIdField } = useBotContext();

	return (
		<Datagrid {...props} expand={<Expand />}>
			<TextField source="name" style={{ fontWeight: "bold" }} />
			{!isSmall && (
				<CommandField
					source="full_command"
					label="resources.unlocks.fields.command"
				/>
			)}
			<NumberField source="actions" />
			<SelectField
				source="action_type"
				choices={actionTypes}
				variant="overline"
			/>
			{!isSmall && (
				<TextField
					source="start_message.name"
					label="resources.unlocks.fields.start_message_id"
				/>
			)}
			{!isSmall && (
				<TextField
					source="result_message.name"
					label="resources.unlocks.fields.result_message_id"
				/>
			)}
			{canShowBotIdField() && <ChipField source="bot.name" label="Bot" />}
			<DateField source="created" showTime />
		</Datagrid>
	);
};

const PredictionList = (props) => {
	return (
		<ListBot
			{...props}
			sort={{ field: "created", order: "desc" }}
			filters={<Filters />}
		>
			<ListItems />
		</ListBot>
	);
};

export default PredictionList;
