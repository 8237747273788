import React from 'react';

import RemoteErrorsInteceptor, { withErrors } from 'ra-remote-validator';
import { SimpleForm } from 'react-admin';
import { compose } from 'recompose';

import Create from '../components/form/Create';
import Form from './Form';

const MessageCreate = ({ dispatch, validate, errors, ...props }) => {
	return (
		<Create {...props}>
			<SimpleForm variant="outlined" redirect="list" validate={validate}>
				<RemoteErrorsInteceptor errors={errors} dispatch={dispatch} />
				<Form create={true} />
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(MessageCreate);
