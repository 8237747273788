import { number, required } from "react-admin";
import { MergeTypeIcon } from "../icons";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";

const string =
	(message = "Must be a string") =>
	(value) =>
		typeof value === "string" || value instanceof String ? undefined : message;
const boolean =
	(message = "Must be a boolean") =>
	(value) =>
		typeof value === "boolean" ||
		value === "false" ||
		value === "true" ||
		value === "1" ||
		value === "0" ||
		value === 1 ||
		value === 0 ||
		value === ""
			? undefined
			: message;
const json =
	(message = "Must be a JSON") =>
	(value) => {
		try {
			JSON.parse(value);
		} catch (e) {
			return false;
		}
	};

export const rules = [
	{ id: "required", name: "required", fn: required },
	{ id: "int", name: "int", fn: number },
	{ id: "string", name: "string", fn: string },
	{ id: "boolean", name: "boolean", fn: boolean },
	{ id: "json", name: "json", fn: json },
];

export const components = [
	{ id: "HiddenInput", name: "HiddenInput" },
	{ id: "TextInput", name: "TextInput" },
	{ id: "BooleanInput", name: "BooleanInput" },
	{ id: "NumberInput", name: "NumberInput" },
	{ id: "ImageInput", name: "ImageInput" },
	{ id: "AudioInput", name: "AudioInput" },
	{ id: "VideoInput", name: "VideoInput" },
	{ id: "MediaInput", name: "MediaInput" },
	{ id: "DocumentInput", name: "DocumentInput" },
	{ id: "RichTextInput", name: "RichTextInput" },
	{ id: "MarkdownInput", name: "MarkdownInput" },
	{
		id: "InlineKeyboardMarkupInput",
		name: "InlineKeyboardMarkupInput",
	},
	{ id: "ReplyKeyboardMarkupInput", name: "ReplyKeyboardMarkupInput" },
];

export default {
	options: {
		group: "admin",
		roles: ["admin"],
	},
	icon: MergeTypeIcon,
	list: List,
	edit: Edit,
	create: Create,
};
