import { green, red, yellow } from "@material-ui/core/colors";

export default [
	{
		id: "pending",
		name: "resources.import_queues.fields.statuses.pending",
		color: yellow[800],
	},
	{
		id: "processing",
		name: "resources.import_queues.fields.statuses.processing",
		color: green[800],
	},
	{
		id: "success",
		name: "resources.import_queues.fields.statuses.success",
		color: green[800],
	},
	{
		id: "error",
		name: "resources.import_queues.fields.statuses.error",
		color: red[600],
	},
];
