import React, { useEffect } from 'react';

import {
  ChipField,
  DateField,
  LinearProgress,
  useListContext,
  useTranslate,
} from 'react-admin';

import {
  AppBar,
  Box,
  makeStyles,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import Datagrid from '../components/lists/Datagrid';
import ListBot from '../components/lists/ListBot';
import { useBotContext } from '../contexts/BotContext';
import {
  FolderContext,
  useFolderContext,
} from '../contexts/FolderContext';
import FolderColorField from '../folders/fields/FolderColorField';
import BulkActionButtons from './BulkActionButtons';
import Expand from './Expand';
import FoldersField from './fields/FoldersField';
import NameField from './fields/NameField';
import PreviewField from './fields/PreviewField';
import Filters from './Filters';
import CloneButton from './inputs/CloneButton';

const useStyles = makeStyles((theme) => ({
	appBar: {
		borderTopLeftRadius: theme.shape.borderRadius,
		borderTopRightRadius: theme.shape.borderRadius,
	},
	tabWrapper: {
		whiteSpace: "nowrap",
	},
}));

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		"aria-controls": `scrollable-auto-tabpanel-${index}`,
	};
}

const ListDatagrid = ({ showFoldersColumn, ...props }) => {
	const theme = useTheme();
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const { filterValues } = useListContext();
	const { canShowBotIdField } = useBotContext();
	return (
		<Datagrid
			{...props}
			rowStyle={(r) => {
				const color = r?.pin_to_top ? theme.palette.primary.main : grey[300];
				return {
					borderLeft: `4px solid ${color}`,
				};
			}}
			expand={<Expand />}
		>
			<NameField source="name" style={{ fontWeight: "bold" }} />
			<PreviewField source="image_preview" />
			{showFoldersColumn !== false &&
				!isSmall &&
				filterValues?.folder_id === 0 && (
					<FoldersField source="folders" label={null} />
				)}
			{/* {!isSmall && (
				<ReferenceField
					source="message_type_id"
					reference="message_types"
					link={false}
				>
					<TextField source="name" variant="overline" />
				</ReferenceField>
			)} */}
			{canShowBotIdField() && <ChipField source="bot.name" label="Bot" />}
			<DateField source="created" showTime />
			<CloneButton />
		</Datagrid>
	);
};

const ListItems = (props) => {
	const [loaded, setLoaded] = React.useState(false);
	const theme = useTheme();
	const translate = useTranslate();
	const classes = useStyles();
	const {
		setFolderId,
		folders,
		loading,
		loaded: folderLoaded,
	} = useFolderContext();
	const { filterValues, setFilters } = useListContext();
	const filterFolderId = filterValues?.folder_id || 0;
	const [value, setValue] = React.useState(filterFolderId);
	const handleChange = (event, folderId) => {
		setValue(folderId);
		setFilters({
			...filterValues,
			folder_id: folderId,
		});
		// setFolderId(folderId);
	};

	useEffect(() => {
		setFolderId(filterFolderId);
	}, [filterFolderId, setFolderId]);

	useEffect(() => {
		if (folderLoaded === true) {
			setLoaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading && !loaded) {
		return <LinearProgress />;
	}

	if ((Object.values(folders)?.length || 0) === 0 && !loaded) {
		return <ListDatagrid {...props} showFoldersColumn={false} />;
	}

	if ((Object.values(folders)?.length || 0) === 0 && loaded) {
		return <ListDatagrid {...props} showFoldersColumn={false} />;
	}

	return (
		<div>
			<AppBar
				position="static"
				color="default"
				elevation={0}
				className={classes.appBar}
			>
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					variant="scrollable"
					scrollButtons="auto"
					aria-label="scrollable auto tabs example"
					TabIndicatorProps={{
						style: {
							backgroundColor:
								folders?.[value]?.color || theme.palette.primary.main,
						},
					}}
				>
					<Tab
						label={
							<Box display="flex" justifyContent="center" alignItems="center">
								<FolderColorField
									source="color"
									record={{ color: theme.palette.primary.main }}
								/>
								&nbsp;&nbsp;
								{translate("ra.boolean.all")}
							</Box>
						}
						{...a11yProps(0)}
						value={0}
					/>
					{Object.values(folders).map((folder, i) => (
						<Tab
							classes={{
								wrapper: classes.tabWrapper,
							}}
							key={folder.id}
							label={
								<Box display="flex" justifyContent="center" alignItems="center">
									<FolderColorField source="color" record={folder} />
									&nbsp;&nbsp;
									{folder?.name}
								</Box>
							}
							value={folder?.id}
							{...a11yProps(i + 1)}
						/>
					))}
				</Tabs>
			</AppBar>
			<ListDatagrid {...props} />
		</div>
	);
};

const List = (props) => {
	return (
		<ListBot
			{...props}
			sort={{ field: "created", order: "DESC" }}
			filters={<Filters />}
			bulkActionButtons={<BulkActionButtons />}
		>
			<ListItems />
		</ListBot>
	);
};

const MessageList = (props) => (
	<FolderContext>
		<List {...props} />
	</FolderContext>
);

export default MessageList;
