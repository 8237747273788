import { createStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import { Login } from "react-admin";

const styles = (theme) =>
	createStyles({
		login: {
			backgroundColor: "#0084C6",
			backgroundImage: "none",
		},
	});

const CustomLoginPage = ({ classes }) => (
	<Login backgroundImage={null} className={classes.login} />
);

export default withStyles(styles)(CustomLoginPage);
