import React from "react";

import RichTextInput from "ra-input-rich-text";
import { useNotify, useTranslate } from "react-admin";
import CopyToClipboard from "react-copy-to-clipboard";

import { Chip, FormHelperText, makeStyles } from "@material-ui/core";

const PLACEHOLDERS = ["username", "firstname", "lastname", "fullname"];

const configureQuill = (quill) => {
	quill.getModule("toolbar").addHandler("bold", function (value) {
		this.quill.format("bold", value);
	});
};

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "space-between",
		marginBottom: theme.spacing(1.75),
		"& .MuiChip-root:first-child": {
			marginLeft: "0 !important",
		},
	},
	chip: {
		marginLeft: theme.spacing(1),
		fontSize: "0.70rem",
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
	},
	helper: {
		fontSize: "0.8035714285714286rem",
	},
}));

const Placeholder = ({ name }) => {
	const classes = useStyles();
	const notify = useNotify();
	const translate = useTranslate();
	return (
		<CopyToClipboard
			text={"{" + name + "}"}
			options={{
				format: "text/plain",
			}}
			onCopy={() =>
				notify(
					translate("resources.targets.fields.copied", {
						name: translate(`resources.targets.fields.${name}`),
					})
				)
			}
		>
			<Chip
				className={classes.chip}
				label={translate(`resources.targets.fields.${name}`)}
				size="small"
			/>
		</CopyToClipboard>
	);
};

const TelegramMessageInput = ({ helperText, ...props }) => {
	const classes = useStyles();
	return (
		<>
			<RichTextInput {...props} configureQuill={configureQuill} />
			<div className={classes.root}>
				<div>
					{PLACEHOLDERS.map((placeholder) => (
						<Placeholder key={placeholder} name={placeholder} />
					))}
				</div>
				<FormHelperText>{helperText}</FormHelperText>
			</div>
		</>
	);
};

export default TelegramMessageInput;
