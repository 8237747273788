import SendIcon from "@material-ui/icons/Send";
import React, { useState } from "react";
import {
	Button,
	Confirm,
	useNotify,
	useRefresh,
	useUnselectAll,
	useUpdateMany,
} from "react-admin";

const MarkAsSentButton = ({ selectedIds }) => {
	const [open, setOpen] = useState(false);
	const refresh = useRefresh();
	const notify = useNotify();
	const unselectAll = useUnselectAll();
	const [updateMany, { loading }] = useUpdateMany(
		"unlock_requests",
		selectedIds,
		{
			send_at: new Date(),
		},
		{
			onSuccess: () => {
				refresh();
				notify("resources.unlock_requests.actions.sent_at.done");
				unselectAll("unlock_requests");
			},
			onFailure: (error) =>
				notify("resources.unlock_requests.actions.sent_at.error", "warning"),
		}
	);

	const handleClick = () => setOpen(true);
	const handleDialogClose = () => setOpen(false);
	const handleConfirm = () => {
		updateMany();
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Confirm
				isOpen={open}
				title="resources.unlock_requests.actions.sent_at.title"
				content="resources.unlock_requests.actions.sent_at.content"
				confirmColor="primary"
				// cancel="Cancel"
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
			/>
			<Button
				icon={<SendIcon />}
				label="resources.unlock_requests.actions.sent_at.title"
				disabled={loading}
				onClick={handleClick}
			/>
		</React.Fragment>
	);
};

export default MarkAsSentButton;
