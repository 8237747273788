import { useSelector as _useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core';

export const useSelector = _useSelector;

const _useListStyles = makeStyles(
	(theme) => ({
		// main: {},
		// rowEven: { backgroundColor: red[500] },
		[theme.breakpoints.up("lg")]: {
			main: (props) => ({
				"& table td, table th": {
					whiteSpace: "nowrap",
				},
			}),
		},
		[theme.breakpoints.down("md")]: {
			main: {
				background: theme.palette.background.default,
				marginTop: theme.spacing(2),
				display: "block",
			},
			content: {
				// background: theme.palette.background.default,
				boxShadow: "none",
				maxWidth: "100%",
			},
			root: {
				padding: theme.spacing(2),
			},
		},
		[theme.breakpoints.down("sm")]: {
			bulkActionsDisplayed: {
				"& .MuiToolbar-regular:first-child": {
					display: "flex",
					flexDirection: "column",
					flexBasis: "auto",
					height: "auto",
					"& .MuiToolbar-root": {
						background: "inherit",
						padding: 0,
						flexWrap: "wrap",
						justifyContent: "flex-start",
						paddingBottom: theme.spacing(1),
						width: "100%",
					},
					"& div:nth-child(1)": {
						paddingTop: theme.spacing(1),
						paddingBottom: theme.spacing(1),
					},
				},
			},
		},
		bulkActionsDisplayed: {
			"& .MuiToolbar-regular:first-child": {
				"& .MuiToolbar-root": {
					flexWrap: "wrap",
				},
			},
		},
	}),
	{ name: "RaMobileList" }
);

export const useListStyles = (sidebarOpen) => _useListStyles(sidebarOpen);

const _useFiltersStyles = makeStyles(
	(theme) => ({
		form: {
			flexWrap: "nowrap",
			"& .filter-field > div:last-child": {
				width: theme.spacing(1),
			},
		},
		[theme.breakpoints.down("md")]: {
			button: { display: "none" },
		},
		[theme.breakpoints.down("xs")]: {
			button: { display: "none" },
			form: {
				flexWrap: "wrap",
				paddingBottom: theme.spacing(1),
				width: "100%",
				"& .filter-field": {
					flexShrink: 0,
					flexGrow: 1,
					width: "100%",
					"& .RaFilterFormInput-spacer-56": {
						display: "none",
					},
				},
				"& .filter-field > div:last-child": {
					width: 0,
				},
			},
		},
	}),
	{ name: "RaFilters" }
);

export const useFiltersStyles = () => _useFiltersStyles();

const _useFormStyles = makeStyles(
	(theme) => ({
		[theme.breakpoints.down("sm")]: {
			main: {
				// width: "calc(100vw - 2em)",
				margin: "1em",
				marginBottom: "6em",
				"& .MuiFormControl-root": {
					width: "100%",
				},
				"& .RaToolbar-spacer-*": {
					display: "none",
				},
				"& .MuiCardContent-root": {
					marginBottom: theme.spacing(2),
					overflow: "auto",
				},
			},
		},
		[theme.breakpoints.up("sm")]: {
			main: {
				width: "70%",
				margin: "1em auto",
			},
		},
	}),
	{ name: "RaMobileList" }
);

export const useFormStyles = () => _useFormStyles();

const _useInputStyles = makeStyles(
	(theme) => ({
		input: { width: theme.spacing(32) },
	}),
	{ name: "RaFormInput" }
);

export const useInputStyles = () => _useInputStyles();
