import { FolderIcon } from '../icons';
import Create from './Create';
import Edit from './Edit';
import List from './List';

export default {
	options: {
		group: "manage",
		roles: ["admin", "user", "publisher"],
	},
	icon: FolderIcon,
	list: List,
	edit: Edit,
	create: Create,
};
