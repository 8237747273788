import { Chip } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { useTranslate } from "react-admin";
import statuses from "../statuses";
import ViewMessagesButton from "../ViewMessagesButton";

const StatusField = ({ source, record, basePath, ...props }) => {
	const translate = useTranslate();
	const value = get(record, source);
	let status = statuses.find((s) => s.id === value);
	if (!status) {
		return null;
	}
	return (
		<React.Fragment>
			<Chip
				{...props}
				style={{
					backgroundColor: get(status, "color", ""),
					color: "white",
				}}
				label={translate(
					`resources.import_queues.fields.statuses.${get(record, source)}`
				)}
			/>
			{value === "success" && (
				<ViewMessagesButton source={source} record={record} />
			)}
		</React.Fragment>
	);
};

export default StatusField;
