import React from 'react';

const Expand = ({ record }) => {
	console.log(record?.data);
	return (
		<code style={{ whiteSpace: "pre" }}>
			{JSON.stringify(record?.data || {}, null, 2)}
		</code>
	);
};

export default Expand;
