import React from 'react';

import { TextField } from 'react-admin';

import { useMediaQuery } from '@material-ui/core';

import Datagrid from '../components/lists/Datagrid';
import List from '../components/lists/List';
import Filters from './Filters';

const MessageTypeList = (props) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	return (
		<List
			{...props}
			filters={<Filters />}
			sort={{ field: "name", order: "ASC" }}
		>
			<Datagrid>
				<TextField source="name" style={{ fontWeight: "bold" }} />
				{!isSmall && <TextField source="method" variant="caption" />}
			</Datagrid>
		</List>
	);
};
export default MessageTypeList;
