import { get } from "lodash";
import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	BooleanInput,
	CheckboxGroupInput,
	email,
	ReferenceArrayInput,
	required,
	SimpleForm,
	TextInput,
} from "react-admin";
import { compose } from "recompose";
import Edit from "../components/form/Edit";
import ClipboardTextInput from "../components/inputs/ClipboardTextInput";

const FormatMany = (many) => (many ? many.map((r) => r.id) : []);
const ParseMany = (many) => (many ? many.map((id) => ({ id })) : []);

const MyCheckboxGroupInput = ({ loaded, ...props }) => (
	<CheckboxGroupInput
		validate={required()}
		{...props}
		choices={props.choices || []}
	/>
);

const UserEdit = ({ dispatch, validate, errors, ...props }) => {
	const permissions = get(props, "permissions", () => {});
	return (
		<Edit title="username" titlePrefix="@" {...props} undoable={false}>
			<SimpleForm variant="outlined" validate={validate}>
				<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
				{permissions("admin") && <BooleanInput source="is_active" />}
				<ClipboardTextInput
					validate={required()}
					source="username"
					disabled={permissions("admin")}
					fullWidth
				/>
				<TextInput validate={[required(), email()]} source="email" fullWidth />
				<TextInput
					validate={required()}
					source="password"
					type="password"
					fullWidth
				/>
				<TextInput validate={required()} source="profile.name" fullWidth />
				<TextInput validate={required()} source="profile.surname" fullWidth />
				<ClipboardTextInput
					source="token"
					defaultValue={localStorage.getItem("token")}
					fullWidth
					disabled={true}
				/>
				{permissions("admin") && (
					<ReferenceArrayInput
						fullWidth
						format={FormatMany}
						parse={ParseMany}
						source="roles"
						reference="roles"
						validate={required()}
					>
						<MyCheckboxGroupInput />
					</ReferenceArrayInput>
				)}
			</SimpleForm>
		</Edit>
	);
};
export default compose(withErrors)(UserEdit);
