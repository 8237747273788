import RemoteErrorsInteceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	CheckboxGroupInput,
	email,
	ReferenceArrayInput,
	required,
	SimpleForm,
	TextInput,
} from "react-admin";
import { compose } from "recompose";
import Create from "../components/form/Create";

const FormatMany = (many) => (many ? many.map((r) => r.id) : []);
const ParseMany = (many) => (many ? many.map((id) => ({ id })) : []);

const MyCheckboxGroupInput = ({ loaded, ...props }) => (
	<CheckboxGroupInput
		validate={required()}
		{...props}
		choices={props.choices || []}
	/>
);

const UserCreate = ({ dispatch, validate, errors, ...props }) => {
	return (
		<Create {...props}>
			<SimpleForm variant="outlined" redirect="list" validate={validate}>
				<RemoteErrorsInteceptor errors={errors} dispatch={dispatch} />
				<TextInput validate={[required(), email()]} source="email" fullWidth />
				<TextInput validate={required()} source="username" fullWidth />
				<TextInput
					validate={required()}
					source="password"
					type="password"
					fullWidth
				/>
				<TextInput
					validate={required()}
					source="profile.name"
					defaultValue=""
					fullWidth
				/>
				<TextInput
					validate={required()}
					source="profile.surname"
					defaultValue=""
					fullWidth
				/>
				<ReferenceArrayInput
					validate={required()}
					format={FormatMany}
					parse={ParseMany}
					source="roles"
					reference="roles"
					fullWidth
				>
					<MyCheckboxGroupInput />
				</ReferenceArrayInput>
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(UserCreate);
