import React, { Fragment } from 'react';

import {
  Filter,
  SearchInput,
} from 'react-admin';

import { useFiltersStyles } from '../../hooks/useStyles';

const Filters = ({ showSearch = true, children, ...props }) => {
	const classes = useFiltersStyles();
	return (
		<Filter classes={classes} {...props} variant="outlined">
			{showSearch && <SearchInput source="q" alwaysOn fullWidth />}
			{React.Children.map(children, (child, i) =>
				React.cloneElement(child ? child : <Fragment />, {
					key: i,
					fullWidth: true,
				})
			)}
		</Filter>
	);
};
export default Filters;
