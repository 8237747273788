import React, {
  Fragment,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import { TextInput } from 'react-admin';
import * as ReactColor from 'react-color';
import { useField } from 'react-final-form';

import {
  Box,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	popup: {
		position: "absolute",
		zIndex: 2,
	},
	cover: {
		position: "fixed",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
	box: {
		width: theme.spacing(2.5),
		height: theme.spacing(2.5),
		borderRadius: "50%",
	},
}));

const ColorInput = ({ source, picker, options, ...props }) => {
	const classes = useStyles();
	const [show, setShow] = useState(false);
	const {
		input: { onChange, value },
	} = useField(source);
	const handleOpen = () => setShow(true);
	const handleClose = () => setShow(false);
	const handleChange = ({ hex }) => onChange(hex);
	const Picker = ReactColor[`${picker}Picker`];

	return (
		<Fragment>
			<TextInput
				onFocus={handleOpen}
				source={source}
				{...props}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Box className={classes.box} style={{ backgroundColor: value }} />
						</InputAdornment>
					),
				}}
			/>
			{show && (
				<div className={classes.popup}>
					<div className={classes.cover} onClick={handleClose} />
					<Picker {...options} color={value} onChange={handleChange} />
				</div>
			)}
		</Fragment>
	);
};

ColorInput.propTypes = {
	label: PropTypes.string,
	options: PropTypes.object,
	source: PropTypes.string,
	input: PropTypes.object,
	helperText: PropTypes.string,
	picker: (props, propName, componentName) =>
		!ReactColor[`${props[propName]}Picker`] &&
		new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`.`),
};

ColorInput.defaultProps = {
	picker: "Chrome",
	options: {
		disableAlpha: true,
	},
};

export default ColorInput;
