import RichTextInput from "ra-input-rich-text";
import React, { Fragment } from "react";

const configureQuill = (quill) => {
	quill.getModule("toolbar").addHandler("bold", function (value) {
		this.quill.format("bold", value);
	});
};

const CaptionInput = ({ source, ...props }) => {
	return (
		<Fragment>
			<RichTextInput
				{...props}
				source={source}
				toolbar={[["bold", "italic", "underline", "link"]]}
				minRows={5}
				fullWidth
				configureQuill={configureQuill}
				variant="filled"
			/>
		</Fragment>
	);
};

export default CaptionInput;
