import React from 'react';

import {
  ChipField,
  SelectInput,
  translate,
} from 'react-admin';

export const types = [
	{
		id: "private",
		name: "resources.targets.fields.types.private",
		color: "default",
	},
	{
		id: "channel",
		name: "resources.targets.fields.types.channel",
		color: "primary",
	},
	{
		id: "group",
		name: "resources.targets.fields.types.group",
		color: "secondary",
	},
	{
		id: "supergroup",
		name: "resources.targets.fields.types.supergroup",
		color: "secondary",
	},
];

export const TypeField = translate(({ record, translate, ...rest }) => {
	const label = types.find((s) => s.id === record.type) || types[0];
	return (
		<ChipField
			{...rest}
			record={{
				...label,
				name: translate(label.name),
			}}
			source="name"
			color={label.color}
		/>
	);
});

export const TypeInput = translate(({ translate, ...rest }) => {
	return (
		<SelectInput
			{...rest}
			source="type"
			label="resources.targets.fields.type"
			choices={types}
		/>
	);
});
