import React, { createContext, useContext, useState } from "react";

const Helper = createContext({});

export const HelperContext = ({ children }) => {
	const initialOpen = JSON.parse(localStorage.getItem("helpers_open"));
	const [open, _setOpen] = useState(initialOpen === null ? true : initialOpen);
	const setOpen = (state) => {
		localStorage.setItem("helpers_open", state);
		_setOpen(state);
	};
	return (
		<Helper.Provider value={{ open, setOpen }}>{children}</Helper.Provider>
	);
};

export const useHelperContext = () => useContext(Helper);
