import React from "react";
import { ReferenceInput, SelectInput } from "react-admin";
import Filters from "../components/form/Filters";
import statuses from "./statuses";

const ImportQueuesFilter = (props) => (
	<Filters {...props}>
		<ReferenceInput source="source_id" reference="sources" alwaysOn>
			<SelectInput optionText="name" />
		</ReferenceInput>
		<SelectInput source="status" choices={statuses} alwaysOn />
	</Filters>
);
export default ImportQueuesFilter;
