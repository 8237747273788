import { createTheme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

export default createTheme({
	palette: {
		background: {
			default: "#EDECEC",
		},
		primary: {
			main: "#005B89",
		},
		secondary: {
			main: "#0084C6",
		},
		error: red,
		contrastThreshold: 3,
		tonalOffset: 0.3,
	},
	// shadows: [0],
	overrides: {
		MuiButton: {
			contained: {
				boxShadow: "none",
			},
		},
	},
});
