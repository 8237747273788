import React from 'react';

import { get } from 'lodash';
import {
  DateField,
  NumberField,
  TextField,
  useTranslate,
} from 'react-admin';

import { useMediaQuery } from '@material-ui/core';

import Datagrid from '../components/lists/Datagrid';
import List from '../components/lists/List';
import BulkActionButtons from './BulkActionButtons';
import Filters from './Filters';

const ListItems = (props) => {
	const hasPredictionFilter = !!get(props, "filterValues.unlock_id");
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const translate = useTranslate();

	return (
		<Datagrid {...props} showEdit={false}>
			{!hasPredictionFilter && (
				<TextField
					source="unlock.name"
					label="resources.unlock_requests.fields.unlock_id"
					style={{ fontWeight: "bold", textTransform: "initial" }}
				/>
			)}
			<TextField
				source="target.name"
				style={{ textTransform: "initial", fontWeight: "bold" }}
			/>
			<TextField
				source="target.username"
				variant="overline"
				style={{ textTransform: "initial" }}
			/>
			{!isSmall && <TextField source="hash" />}
			<NumberField source="actions" />
			<DateField source="send_at" showTime emptyText="-" />
			<DateField
				source="created"
				showTime
				emptyText={translate("ra.message.never")}
			/>
		</Datagrid>
	);
};

const PredictionRequestList = (props) => {
	return (
		<List
			{...props}
			sort={{ field: "created", order: "desc" }}
			filters={<Filters />}
			bulkActionButtons={<BulkActionButtons />}
		>
			<ListItems />
		</List>
	);
};
export default PredictionRequestList;
