import React from 'react';

import { get } from 'lodash';
import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  Loading,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useQueryWithStore,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';

const InlineKeyboardMarkupInput = (props) => {
	const translate = useTranslate();
	const formState = useFormState();
	const botId = get(formState.values, "bot_id", null);
	const { data: messages, loading } = useQueryWithStore({
		type: "getList",
		resource: "callbacks",
		payload: {
			sort: { field: "id", order: "desc" },
			pagination: {
				page: 1,
				perPage: 2500,
			},
			filter: { bot_id: botId },
		},
	});

	if (loading) {
		return <Loading />;
	}

	return (
		<ArrayInput {...props}>
			<SimpleFormIterator
				disableReordering
				TransitionProps={{
					enter: false,
				}}
			>
				<TextInput
					label={translate("resources.inline_keyboard_markup.fields.text")}
					source="text"
					validate={required()}
					fullWidth
				/>
				<SelectInput
					source="action_type"
					label={translate(
						"resources.inline_keyboard_markup.fields.action_type"
					)}
					validate={required()}
					choices={[
						{ id: "url", name: "URL" },
						{ id: "callback", name: translate("resources.messages.name", 1) },
					]}
					defaultValue="url"
					fullWidth
				/>
				<FormDataConsumer>
					{({ formData, scopedFormData, getSource, ...rest }) => {
						if (!scopedFormData) {
							return null;
						}
						const choices =
							botId && messages
								? Object.keys(messages).map((id) => ({
										id: messages[id].id,
										name: `[${messages[id].id}] - ${messages[id].name}`,
								  }))
								: [];
						switch (scopedFormData.action_type) {
							case "url":
								return (
									<TextInput
										{...props}
										label={translate(
											"resources.inline_keyboard_markup.fields.url"
										)}
										validate={required()}
										source={getSource("url")}
									/>
								);
							case "callback":
								return (
									<>
										<AutocompleteInput
											{...props}
											label={translate(
												"resources.inline_keyboard_markup.fields.callback_data"
											)}
											validate={required()}
											source={getSource("callback_data")}
											choices={choices}
										/>
										<SelectInput
											{...props}
											label={translate(
												"resources.inline_keyboard_markup.fields.callback_data_delete_previous_message"
											)}
											validate={required()}
											source={getSource(
												"callback_data_delete_previous_message"
											)}
											defaultValue={false}
											choices={[
												{ id: false, name: "ra.boolean.no" },
												{ id: true, name: "ra.boolean.yes" },
											]}
										/>
									</>
								);
							default:
								return null;
						}
					}}
				</FormDataConsumer>
			</SimpleFormIterator>
		</ArrayInput>
	);
};
export default InlineKeyboardMarkupInput;
