import { QueueIcon } from "../icons";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";

export default {
	options: {
		group: "send",
		roles: ["admin", "user", "publisher"],
	},
	icon: QueueIcon,
	list: List,
	edit: Edit,
	create: Create,
};
