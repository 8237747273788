import React from 'react';

import { get } from 'lodash';
import {
  Button,
  useListContext,
  useRedirect,
} from 'react-admin';

import SendIcon from '@material-ui/icons/Send';

const SendMessagesButton = ({
	basePath = "",
	label = "resources.messages.buttons.send",
	selectedIds,
	...props
}) => {
	const { data } = useListContext();
	const redirect = useRedirect();

	const botIds = Object.keys(data)
		.map((key) => ({
			id: data[key].id,
			bot_id: data[key].bot_id,
		}))
		.filter((m) => selectedIds.includes(m.id))
		.map((m) => m.bot_id);
	const uniqueBotIds = [...new Set(botIds)];

	const handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		redirect(
			"create",
			"/queues",
			null,
			{},
			{
				record: {
					bot_id: get(uniqueBotIds, "0"),
					messages: selectedIds,
				},
			}
		);
	};

	return (
		<Button
			color="secondary"
			onClick={handleClick}
			label={label}
			disabled={uniqueBotIds.length > 1}
		>
			<SendIcon fontSize="small" />
		</Button>
	);
};

export default SendMessagesButton;
