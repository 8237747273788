import React from 'react';

import { TextField } from 'react-admin';

import Datagrid from '../components/lists/Datagrid';
import List from '../components/lists/List';
import Filters from './Filters';

const RoleList = (props) => {
	return (
		<List
			{...props}
			filters={<Filters />}
			sort={{ field: "name", order: "ASC" }}
		>
			<Datagrid>
				<TextField source="name" style={{ fontWeight: "bold" }} />
				<TextField source="code" variant="caption" />
			</Datagrid>
		</List>
	);
};
export default RoleList;
