import React, { useCallback } from 'react';

import classnames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { toggleSidebar } from 'ra-core';
import {
  LoadingIndicator,
  useGetIdentity,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { useDispatch } from 'react-redux';

import {
  AppBar as MuiAppBar,
  Button as MuiButton,
  IconButton,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import MenuIcon from '@material-ui/icons/Menu';

import { useBotContext } from './contexts/BotContext';
import { useHelperContext } from './contexts/HelperContext';
import { AndroidIcon } from './icons';
import UserMenu from './UserMenu';

const useStyles = makeStyles((theme) => ({
	title: {
		flexGrow: 1,
	},
	titleSidebarClose: {
		marginLeft: theme.spacing(2),
	},
	appBar: {
		flexGrow: 1,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: ({ drawerWidth }) => ({
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth - 2}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	menuButton: {
		marginRight: theme.spacing(2),
		marginLeft: -20,
		[theme.breakpoints.down("sm")]: {
			marginRight: theme.spacing(0),
		},
	},
	hide: {
		display: "none",
	},
	spacer: {
		flex: 1,
		flexGrow: 1,
	},
}));

const AppBar = ({
	open,
	logout,
	drawerWidth,
	location,
	userMenu = UserMenu,
}) => {
	const { getSelectedBotNames } = useBotContext();
	const translate = useTranslate();
	const redirect = useRedirect();
	const { open: showHelper, setOpen: setShowHelper } = useHelperContext();
	const classes = useStyles({ drawerWidth });
	const dispatch = useDispatch();
	const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	const { identity } = useGetIdentity();
	const handleToggleSidebar = useCallback(
		() => dispatch(toggleSidebar()),
		[dispatch]
	);
	return (
		<MuiAppBar
			position="fixed"
			color="secondary"
			elevation={0}
			className={classnames(classes.appBar, {
				[classes.appBarShift]: open && !isXSmall,
			})}
		>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={handleToggleSidebar}
					edge="start"
					className={classnames(classes.menuButton, {
						[classes.hide]: open && !isXSmall,
					})}
				>
					<MenuIcon />
				</IconButton>
				<Typography
					className={classnames(classes.title, {
						[classes.titleSidebarClose]: !open,
					})}
					variant="h6"
					id="react-admin-title"
					noWrap
				/>
				<MuiButton
					variant="text"
					color="inherit"
					aria-label={getSelectedBotNames()}
					onClick={() => {
						const path = location.pathname.substring(1);
						redirect(`/bots?back=${path}`);
					}}
					startIcon={<AndroidIcon />}
				>
					{!isXSmall ? (
						<Tooltip title={getSelectedBotNames(true)}>
							<Typography variant="overline">
								{getSelectedBotNames()}
							</Typography>
						</Tooltip>
					) : (
						<Tooltip title={getSelectedBotNames(true)}>
							<Typography variant="overline">
								{translate("resources.bots.name", 0)}
							</Typography>
						</Tooltip>
					)}
				</MuiButton>
				{!isXSmall && <div className={classes.spacer} />}
				{!isXSmall && (
					<Typography variant="body1">
						{get(identity, "fullName", "")}
					</Typography>
				)}
				<LoadingIndicator />
				{!showHelper && (
					<Tooltip title={translate("ra.helpers.help")}>
						<IconButton
							aria-label={translate("ra.helpers.help")}
							color="inherit"
							onClick={() => setShowHelper(true)}
						>
							<HelpIcon />
						</IconButton>
					</Tooltip>
				)}
				{React.createElement(userMenu, { logout, location })}
			</Toolbar>
		</MuiAppBar>
	);
};

AppBar.propTypes = {
	open: PropTypes.bool.isRequired,
	logout: PropTypes.element.isRequired,
	drawerWidth: PropTypes.number.isRequired,
	userMenu: PropTypes.elementType,
};

export default AppBar;
