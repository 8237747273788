import React from 'react';

import {
  ChipField,
  DateField,
  ReferenceField,
  TextField,
} from 'react-admin';

import Datagrid from '../components/lists/Datagrid';
import ListBot from '../components/lists/ListBot';
import { useBotContext } from '../contexts/BotContext';
import NameField from '../messages/fields/NameField';
import Filters from './Filters';
import { StatusField } from './Status';

const ListItems = (props) => {
	const { canShowBotIdField } = useBotContext();
	return (
		<Datagrid {...props}>
			<ReferenceField source="message_id" reference="messages">
				<NameField source="name" />
			</ReferenceField>
			<ChipField
				source="target.name"
				label="resources.queues.fields.target_id"
			/>
			<StatusField source="status" />
			<TextField
				source="code"
				variant="overline"
				style={{ fontWeight: "bold" }}
			/>
			{canShowBotIdField() && <ChipField source="bot.name" label="Bot" />}
			<DateField source="send_date" showTime />
			<DateField source="pin_to_top_date" showTime />
			<DateField source="delete_date" showTime emptyText="-" />
		</Datagrid>
	);
};

const QueueList = (props) => (
	<ListBot
		{...props}
		filters={<Filters />}
		sort={{ field: "send_date", order: "DESC" }}
	>
		<ListItems />
	</ListBot>
);
export default QueueList;
