import React from 'react';

import {
  Button,
  useRefresh,
  useTranslate,
  useUnselectAll,
  useUpdateMany,
} from 'react-admin';

import StarIcon from '@material-ui/icons/Star';

const PinToTopButton = ({ selectedIds }) => {
	const translate = useTranslate();
	const unselectAll = useUnselectAll("messages");
	const refresh = useRefresh();
	const [updateMany, { loading }] = useUpdateMany(
		"messages",
		selectedIds,
		{
			pin_to_top: true,
		},
		{
			onSuccess: () => {
				unselectAll();
				refresh();
			},
		}
	);

	return (
		<Button
			color="secondary"
			onClick={updateMany}
			label={translate("resources.messages.actions.pin_to_top")}
			disabled={loading}
		>
			<StarIcon />
		</Button>
	);
};

export default PinToTopButton;
