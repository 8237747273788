import React from 'react';

import { get } from 'lodash';
import {
  Button,
  TextInput,
  useInput,
  useNotify,
} from 'react-admin';
import CopyToClipboard from 'react-copy-to-clipboard';

import { InputAdornment } from '@material-ui/core';

const ApiKeyInput = ({ source, ...props }) => {
	const notify = useNotify();
	const { input } = useInput(source);
	const value = get(input, `value.${source}`);
	return (
		<TextInput
			{...props}
			source={source}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Button
							onClick={() =>
								window.open(
									`https://api.telegram.org/bot${value}/getWebhookInfo`
								)
							}
							disabled={!value}
							label="resources.bots.actions.info"
						/>
						<CopyToClipboard
							text={value}
							onCopy={() => notify("ra.action.content_copied")}
							disabled={!value}
						>
							<Button onClick={() => {}} label="ra.action.copy" />
						</CopyToClipboard>
					</InputAdornment>
				),
			}}
		/>
	);
};

export default ApiKeyInput;
