import React from 'react';

import { makeStyles } from '@material-ui/core';

import ImageField from '../../components/fields/ImageField';

const useStyles = makeStyles((theme) => ({
	image: {
		paddingInlineStart: 0,
		paddingInlineEnd: 0,
		marginBlockStart: 0,
		marginBlockEnd: 0,
		"& img": {
			width: 80,
			height: 80,
			objectFit: "contain",
			borderRadius: theme.shape.borderRadius,
		},
	},
}));

const PreviewField = ({ source, record }) => {
	const preview = record?.[source] || null;
	const classes = useStyles();

	if (!preview) {
		return null;
	}

	return (
		<ImageField
			source={source}
			record={record}
			title={record.name}
			className={classes.image}
			src="src"
		/>
	);
};

export default PreviewField;
