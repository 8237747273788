import React from 'react';

import {
  Box,
  makeStyles,
  Tooltip,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	avatar: {
		width: theme.spacing(2),
		height: theme.spacing(2),
		borderRadius: "50%",
		display: "inline-block",
	},
}));

const Field = ({ classes, ...props }) => (
	<Box
		className={classes.avatar}
		style={{
			backgroundColor: props?.record?.[props?.source] || "#000",
		}}
	/>
);

const FolderColorField = ({ tooltip = false, ...props }) => {
	const classes = useStyles();

	if (!tooltip) {
		return <Field classes={classes} {...props} />;
	}

	return (
		<Tooltip title={props?.record?.name || ""}>
			<Box
				className={classes.avatar}
				style={{
					backgroundColor: props?.record?.[props?.source] || "#000",
				}}
			/>
		</Tooltip>
	);
};

export default FolderColorField;
