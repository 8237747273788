import React from 'react';

import FolderColorField from '../../folders/fields/FolderColorField';

const FoldersField = (props) => {
	const folders = props?.record?.[props?.source] || [];
	return (
		<>
			{folders.map((folder, index) => (
				<FolderColorField
					key={index}
					record={folder}
					source="color"
					tooltip={true}
				/>
			))}
		</>
	);
};

export default FoldersField;
