import React from "react";
import { NullableBooleanInput, ReferenceInput, SelectInput } from "react-admin";
import Filters from "../components/form/Filters";

const UserFilters = (props) => (
	<Filters {...props}>
		<ReferenceInput source="role" reference="roles" allowEmpty alwaysOn>
			<SelectInput optionText="name" fullWidth />
		</ReferenceInput>
		<NullableBooleanInput source="active" alwaysOn fullWidth />
	</Filters>
);
export default UserFilters;
