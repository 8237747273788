import VpnKeyIcon from "@material-ui/icons/VpnKey";
import React, { useState } from "react";
import {
	Button,
	useAuthProvider,
	useNotify,
	useRedirect,
	useTranslate,
} from "react-admin";

const LoginButton = ({ label = "ra.auth.sign_in", record, ...rest }) => {
	const [loading, setLoading] = useState(false);
	const redirect = useRedirect();
	const notify = useNotify();
	const translate = useTranslate();
	const authProvider = useAuthProvider();
	const handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setLoading(true);

		authProvider
			.impersonate(record.id)
			.then(() => {
				setLoading(false);
				notify(translate("ra.auth.sign_in_success"), "success");
				redirect("/");
				setTimeout(() => window.location.reload(), 200);
			})
			.catch(() => {
				setLoading(false);
				notify(translate("ra.auth.sign_in_error"), "warning");
			});
	};

	return (
		<Button
			disabled={loading}
			color="inherit"
			variant="text"
			label={label}
			onClick={handleClick}
			{...rest}
		>
			<VpnKeyIcon />
		</Button>
	);
};

export default LoginButton;
