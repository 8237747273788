import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import { get } from "lodash";
import React from "react";
import { Button, useListContext, useRedirect } from "react-admin";

const SendMessagesButton = ({
	basePath = "",
	label = "resources.replies.buttons.reply_with_message",
	selectedIds,
	...props
}) => {
	const { data } = useListContext();
	const redirect = useRedirect();

	const selectedData = Object.keys(data)
		.map((key) => ({
			id: data[key].id,
			bot_id: data[key].bot_id,
			target_id: data[key].target_id,
		}))
		.filter((r) => selectedIds.includes(r.id));
	const botIds = selectedData.map((r) => r.bot_id);
	const targetIds = selectedData.map((r) => r.target_id);
	const uniqueBotIds = [...new Set(botIds)];
	const uniqueTargetIds = [...new Set(targetIds)];

	const handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		redirect(
			"create",
			"/queues",
			null,
			{},
			{
				record: {
					bot_id: get(uniqueBotIds, "0"),
					targets: uniqueTargetIds,
				},
			}
		);
	};

	return (
		<Button
			color="secondary"
			onClick={handleClick}
			label={label}
			disabled={uniqueBotIds.length > 1}
		>
			<ReplyAllIcon fontSize="small" />
		</Button>
	);
};

export default SendMessagesButton;
