import React from "react";

import { BulkDeleteButton } from "react-admin";

import AddFoldersButton from "./buttons/AddFoldersButton";
import CloneAllButton from "./buttons/CloneAllButton";
import FoldersButton from "./buttons/FoldersButton";
import PinToTopButton from "./buttons/PinToTopButton";
import RemoveFoldersButton from "./buttons/RemoveFoldersButton";
import UnpinFromTopButton from "./buttons/UnpinFromTopButton";
import SendMessagesButton from "./inputs/SendMessagesButton";

const BulkActionButtons = (props) => {
	return (
		<React.Fragment>
			<SendMessagesButton {...props} />
			<CloneAllButton {...props} />
			<PinToTopButton {...props} />
			<UnpinFromTopButton {...props} />
			<FoldersButton {...props} />
			<AddFoldersButton {...props} />
			<RemoveFoldersButton {...props} />
			<BulkDeleteButton {...props} />
		</React.Fragment>
	);
};

export default BulkActionButtons;
