import { makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useHelperContext } from "../contexts/HelperContext";

const useStyles = makeStyles((theme) => ({
	[theme.breakpoints.down("md")]: {
		container: {
			paddingTop: theme.spacing(2),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
	},
	content: {
		whiteSpace: "pre-line",
	},
}));

const HelperAlert = () => {
	const [title, setTitle] = useState(null);
	const [content, setContent] = useState(null);
	const { open, setOpen } = useHelperContext();
	const location = useLocation();
	const match = useRouteMatch("/:resource/:id");
	const isEdit = match && get(match, "params.id") !== "create";
	const pathname = get(location, "pathname");
	const translate = useTranslate();
	const classes = useStyles();
	const getResource = (pathname) => {
		let parts = pathname.split("/");
		parts.shift();
		if (isEdit) {
			parts[parts.length - 1] = "edit";
		}
		return parts.join(".").replace(/[0-9]/g, "edit");
	};
	const resource = getResource(pathname);

	useEffect(() => {
		const helpTitleKey = `helpers.${resource}.title`;
		const helpContentKey = `helpers.${resource}.content`;
		const helpTitle = translate(helpTitleKey);
		const helpContent = translate(helpContentKey);

		if (helpTitle !== helpTitleKey && helpContent !== helpContentKey) {
			setTitle(helpTitle);
			setContent(helpContent);
		} else {
			setTitle(null);
			setContent(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resource]);

	if (!open || !title || !content) return null;

	return (
		<div className={classes.container}>
			<Alert severity="info" onClose={() => setOpen(false)}>
				<AlertTitle>{title}</AlertTitle>
				<div className={classes.content}>{content}</div>
			</Alert>
		</div>
	);
};

export default HelperAlert;
