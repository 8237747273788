import { merge } from 'lodash';
import italianMessages from 'ra-language-italian';

const standardMessages = {
	"Unprocessable Entity": "Errore di validazione",
};
const appMessages = {
	ra: {
		boolean: {
			null: "Nessuno",
			all: "Tutti",
			yes: "Si",
			no: "No",
		},
		message: {
			none: "Nessuno",
			never: "Mai",
		},
		page: {
			empty: "Nessun risultato trovato",
			invite: "Clicca il pulsante sottostante per aggiungere risorse",
		},
		action: {
			copy: "Copia",
			import: "Importa",
			remove: "Rimuovi",
			send: "Invia",
			unselect: "Deseleziona",
			content_copied: "Contenuto copiato",
		},
		date: {
			today: "Oggi",
		},
		auth: {
			profile: "Profilo",
			sub_sign_out: "Disconnessione sottoutente",
			sign_in_success: "Login effettuato correttamente",
			auth_check_error: "Impossibile loggarsi",
			impersonate_logout: "Smetti di impersonare",
		},
		validation: {
			url: "URL non valido",
		},
	},
	menu: {
		groups: {
			dashboard: "Dashboard",
			automation: "Automazione",
			send: "Invio",
			manage: "Gestione",
			admin: "Amministrazione",
		},
		items: {
			bots: "Bots",
			targets: "Destinazioni",
			message_types: "Tipologie messaggi",
			messages: "Messaggi",
			queues: "Code di invio",
			replies: "Risposte",
			users: "Utenti",
			roles: "Ruoli",
			unlocks: "Azioni di sblocco",
			unlock_requests: "Richieste di sblocco",
			import_queues: "Importazione messaggi",
			folders: "Cartelle",
			updates: "Aggiornamenti",
		},
	},
	app: {
		no_records: "Nessun record restitutio",
		action: {
			clear: "Pulisci",
			print: "Stampa",
			none: "Nessuno",
		},
	},
	resources: {
		bots: {
			name: "Bot |||| Bots",
			fields: {
				id: "#ID",
				message_id: "Messaggio Automatico",
				user_id: "Utente",
				name: "Nome del bot",
				api_key: "Telegram Bot API Key",
				username: "Username",
				created: "Creato il",
				status: "Status",
				"status.connected": "Connesso",
				"status.disconnected": "Disconnesso",
				modified: "Ultima modifica",
				q: "Chiave di ricerca",
			},
			actions: {
				info: "Info webhook",
				register: "Connetti",
				unregister: "Disconnetti",
				select: "Seleziona un bot",
				select_this: "Seleziona bot",
				unselect_this: "Deseleziona bot",
				selected: "%{count} bot selezionati",
				please_select:
					"Nessun bot selezionato, si prega di selezionare un bot per proseguire.",
			},
		},
		folders: {
			name: "Cartella |||| Cartelle",
			fields: {
				id: "#ID",
				name: "Nome della cartella",
				color: "Colore",
			},
		},
		message_types: {
			name: "Tipologia messaggio |||| Tipologia messaggi",
			fields: {
				id: "#ID",
				name: "Nome",
				method: "Metodo di invio",
				data: "Schema JSON",
				q: "Chiave di ricerca",
				message_type_fields: "Campi del messaggio",
				required: "Campo obbligatorio",
				default: "Valore di default",
				rules: "Validazioni",
				component: "Componente",
			},
		},
		messages: {
			name: "Messaggio |||| Messaggi",
			fields: {
				id: "#ID",
				user_id: "Utente",
				bot_id: "Bot",
				import_queue_id: "Importazione messaggio",
				message_type_id: "Tipologia messaggio",
				name: "Nome del messaggio",
				command: "Comando per richiamare il messaggio",
				data: "Contenuto",
				created: "Creato il",
				modified: "Ultima modifica",
				q: "Chiave di ricerca",
				pin_to_top: "Fissato in alto",
				unpin_from_top: "Sbloccato",
				folder_ids: "Cartelle",
				image_preview: "Anteprima",
			},
			buttons: {
				send: "Invia",
			},
			actions: {
				save_send: "Salva e invia",
				pin_to_top: "Fissa in alto",
				unpin_from_top: "Sblocca",
				select_folders: "Seleziona cartelle",
				add_folders: "Aggiungi cartelle",
				remove_folders: "Rimuovi cartelle",
				clone_all: "Duplica selezionati",
				clone_all_title:
					"Duplica %{smart_count} messaggio selezionato |||| Duplica %{smart_count} messaggi selezionati",
				clone_all_help: "Seleziona il bot a cui associare i messaggi duplicati",
				cloned_all_success:
					"I messaggi selezionati sono stati duplicati. %{cloned} messaggi sono stati duplicati correttamente, %{errors} messaggi non sono stati clonati perché si sono verificati degli errori.",
				cloned_all_error:
					"Errore durante la duplicazione dei messaggi selezionati",
			},
			errors: {
				is_unique:
					"Il comando è già stato associato ad un altro messaggio per questo bot",
			},
		},
		messages_message_type_fields: {
			fields: {
				text: "Testo del messaggio",
				disable_web_page_preview: "Disabilita anteprima pagina web",
				disable_notification: "Disabilita notifiche",
				reply_markup: "Tasti di risposta rapida",
				photo: "Immagine",
				caption: "Didascalia",
				duration: "Durata",
				performer: "Artista",
				audio: "File audio",
				title: "Titolo",
				thumb: "Miniatura",
				video: "File video",
				width: "Larghezza",
				height: "Altezza",
				supports_streaming: "Abilita streaming",
				voice: "File vocale",
				media: "Files multimediali",
			},
			helpers: {
				caption: "%{count} di %{limit}",
			},
		},
		inline_keyboard_markup: {
			fields: {
				text: "Testo del pulsante",
				url: "URL del pulsante",
				action_type: "Tipologia azione",
				callback_data: "ID Messaggio collegato",
				callback_data_delete_previous_message: "Elimina messaggio precedente",
				"callback_data_delete_previous_message.help":
					"Selezionando questa opzione, il messaggio precedente verrà eliminato prima di inviare il nuovo messaggio",
			},
		},
		queues: {
			name: "Coda di invio |||| Code di invio",
			fields: {
				id: "#ID",
				code: "Codice",
				user_id: "Utente",
				message_id: "Messaggio",
				message_ids: "Messaggi",
				target_id: "Destinazione",
				target_ids: "Destinazioni",
				bot_ids: "Bots",
				content: "Contenuto",
				status: "Status",
				delete_date: "Data di cancellazione",
				delete_after: "Cancellazione automatica",
				"delete_after.help":
					'I messaggi possono essere cancellati massimo 48 ore dopo l\'invio. Se inserisci un valore superiore, verrà impostato automaticamente a 48 ore. Se hai deciso di fissare in alto il messaggio, la data presa in considerazione sarà quella del "Fissa in alto" anzichè quella di invio del messaggio.',
				delete_after_frequency: "Cancella dopo",
				delete_after_frequency_type: "Tipologia intervallo",
				pin_to_top_date: "Data di di fissaggio in alto",
				pin_to_top_after: "Fissa in alto automaticamente",
				"pin_to_top_after.help":
					"Imposta la data di fissaggio in alto del messaggio. NB: se non specifichi una data, il messaggio verrà fissato in alto dopo 1 ora.",
				pin_to_top_after_frequency: "Fissa in alto dopo",
				pin_to_top_after_frequency_type: "Tipologia intervallo",
				send_date: "Data di invio",
				"send_date.alert":
					"Attenzione, hai selezionato una data antecedente a quella attuale",
				response: "Risposta",
				created: "Creato il",
				modified: "Ultima modifica",
				q: "Chiave di ricerca",
				messages: "Messaggi da inviare",
				targets: "Destinazioni",
				targets_excluded: "Escludi destinazioni",
				target_types:
					"Seleziona a quali tipi di destinazione vuoi inviare il messaggio",
				send_all: "Invia a tutte le destinazioni del bot",
				send_all_unlockers:
					"Invia agli utenti che hanno compiuto correttamente un'azione di sblocco",
				statuses: {
					pending: "In attesa",
					sent: "Inviato",
					error: "Impossibile inviare",
					sending: "Invio in corso",
					deleting: "Cancellazione in corso",
					deleted: "Cancellato",
					pinning_to_top: "Fissaggio in alto in corso",
					pinned_to_top: "Fissato in alto",
				},
				repeat: "Ripeti",
				start_date: "Data di inizio",
				send_date_start: "Data di inizio",
				end_date: "Data di fine",
				send_date_end: "Data di fine",
				frequency: "Ripeti ogni",
				frequency_type: "Tipologia intervallo",
				frequency_types: {
					minutes: "Minuto |||| Minuti",
					hours: "Ora |||| Ore",
					days: "Giorno |||| Giorni",
					weeks: "Settimana |||| Settimane",
					months: "Mese |||| Mesi",
					years: "Anno |||| Anni",
				},
			},
			create: {
				confirm: {
					title: "Conferma programmazione",
					description:
						"Attenzione: stai cercando di creare una programmazione per più di 1 giorno,sei sicuro di voler programmare l'invio dei messaggi selezionati?",
				},
			},
		},
		replies: {
			name: "Risposta |||| Risposte",
			send_reply: "Invia risposta a",
			fields: {
				id: "#ID",
				user_id: "Utente",
				bot_id: "Bot",
				chat_id: "Chat",
				telegram_from_id: "Telegram ID",
				telegram_from_username: "Telegram Username",
				data: "Testo",
				last_message: "Data ultimo messaggio",
				created: "Creato il",
				modified: "Ultima modifica",
				q: "Chiave di ricerca",
				exclude_start: "Escludi messaggio /start",
			},
			actions: {
				send: "Invia",
				sending: "Invio in corso...",
			},
			buttons: {
				reply_with_message: "Risp. con Messaggio",
				reply: "Risp. Veloce",
			},
			messages_sent: "Messaggi inviati correttamente",
		},
		targets: {
			name: "Destinazione |||| Destinazioni",
			fields: {
				id: "#ID",
				user_id: "Utente",
				bot_id: "Bot",
				name: "Nome",
				type: "Tipologia",
				types: {
					channel: "Canale",
					group: "Gruppo",
					supergroup: "Supergruppo",
					private: "Utente",
					unlockers:
						"Chiunque abbia eseguito correttamente un'azione di sblocco",
					ununlockers:
						"Chiunque non abbia ancora eseguito un'azione di sblocco",
				},
				telegram_id: "Telegram ID",
				telegram_username: "Telegram Username",
				telegram_firstname: "Telegram Nome",
				telegram_lastname: "Telegram Cognome",
				telegram_fullname: "Telegram Nome completo",
				username: "Username",
				firstname: "Nome",
				lastname: "Cognome",
				fullname: "Nome completo",
				auto_accept_join_requests:
					"Accetta automaticamente le richieste di entrata",
				"auto_accept_join_requests.help": "Solo per canali e supergruppi",
				auto_accept_join_requests_delay: "Ritardo accettazione richieste",
				"auto_accept_join_requests_delay.help":
					"Specifica dopo quanti minuti accettare le richieste di entrata",
				chat_invite_link_name: "Nome link invito",
				"chat_invite_link_name.help":
					"Inserisci un nome per il link invito, es: Gennaio 2024",
				chat_invite_link_member_limit: "Limite membri link invito",
				"chat_invite_link_member_limit.help":
					"Specifica il numero massimo di membri che possono entrare nel gruppo tramite il link invito",
				chat_invite_link_creates_join_request: "Crea richiesta di entrata",
				"chat_invite_link_creates_join_request.help":
					"Se abilitato, chiunque utilizzi il link invito dovrà essere accettato manualmente",
				chat_invite_link_password: "Password per creare link invito",
				"chat_invite_link_password.help":
					"Se inserisci una password per la creazione link invito verrà abilitato il comando /invite %{password} per creare link di invito associati a questa destinazione",
				chat_invite_link_expire_date: "Scadenza link invito",
				"chat_invite_link_expire_date.help":
					"Specifica la data di scadenza del link invito",
				created: "Acquisito il",
				modified: "Ultima modifica",
				q: "Chiave di ricerca",
				copied:
					"%{name} copiato correttamente, inseriscilo nella posizione desiderata nel tuo messaggio",
			},
		},
		roles: {
			name: "Ruolo |||| Ruoli",
			fields: {
				code: "Codice",
				name: "Nome",
				created: "Data di creazione",
				modified: "Ultima Modifica",
				q: "Chiave di ricerca",
			},
		},
		users: {
			name: "Utente |||| Utenti",
			fields: {
				is_active: "Attivo",
				roles: "Ruoli",
				groups: "Gruppi",
				profile: {
					title: "Profilo",
					name: "Nome",
					surname: "Cognome",
				},
				// Filters
				role: "Ruolo",
				group: "Gruppo",
				active: "Attivo",
				perms: "Permessi",
				q: "Chiave di ricerca",
			},
		},
		unlocks: {
			name: "Azione di sblocco |||| Azioni di sblocco",
			fields: {
				q: "Chiave di ricerca",
				name: "Nome",
				bot_id: "Bot",
				command: "Comando",
				actions: "Azioni",
				action_type: "Tipo di azione",
				start_message_id: "Messaggio iniziale",
				result_message_id: "Messaggio finale",
				url: "URL",
				"url.clicks": "Link da inviare agli amici",
				"url.instagram_comments": "Link post Instagram",
				"url.telegram": "ID/Username canale Telegram",
				created: "Creato il",
				modified: "Ultima modifica",
			},
			helpers: {
				name: "Il nome da dare al comando per identificarlo all'interno della piattaforma",
				command:
					"Il comando che verrà eseguito su Telegram per avviare l'azione di sblocco",
				actions:
					"Il numero di azioni da raggiungere per inviare il 'Messaggio finale'",
				action_type: "Tipologia di azione da compiere",
				"action_type.clicks":
					"L'utente dovrà far cliccare il LINK sottostante a %{actions} amici",
				"action_type.instagram_comments":
					"L'utente dovrà taggare %{actions} amici nei commenti del post sottostante",
				"action_type.telegram":
					"L'utente dovrà invitare %{actions} amici ad iscriversi al canale Telegram sottostante",
				start_message_id:
					"Il messaggio iniziale che verrà mostrato all'utente quando verrà digitato il comando.",
				result_message_id:
					"Il messaggio finale che verrà inviato all'utente, quando saranno state compiute tutte le azioni.",
				url: "Il Link dove deve essere reindirizzato l'utente",
				"url.clicks":
					"Il Link che l'utente deve far cliccare a %{actions} amici",
				"url.instagram_comments":
					"Il Link del post instagram dove l'utente deve taggare %{actions} amici nei commenti. ES: https://www.instagram.com/p/CGhcCSSFOHL/",
				"url.telegram":
					"L'ID/Username del canale Telegram da incrementare, se il canale è privato è necessario inserire l'ID del canale. ES: -1001723590239. Puoi recuperare l'ID del canale dopo aver inserito il BOT nello stesso e aver scritto almeno un msg. Successivamente troverai l'id del canale nella sezione RISPOSTE.",
			},
			actions: {
				clicks: "Clicks",
				instagram_comments: "Tag commenti instagram",
				requests: "Richieste",
				telegram: "Telegram",
			},
			insights: {
				total: "Totale richieste",
				payloaded: "Persone che hanno interagito",
				sent: "Messaggi inviati",
			},
		},
		unlock_requests: {
			name: "Richieste di sblocco",
			fields: {
				q: "Chiave di ricerca",
				unlock_id: "Azione di sblocco",
				"target.name": "Nome telegram",
				"target.username": "Username telegram",
				sent: "Inviato",
				hash: "Link o codice",
				send_at: "Inviato il",
				payloaded: "Richiesto",
				payload: "Richiesta del bot",
				actions: "Azioni compiute",
				created: "Richiesto il",
			},
			actions: {
				sent_at: {
					title: "Contrassegna come Inviato",
					content:
						"Sei sicuro di voler contrassegnare le richieste selezionate come 'Inviate'?",
					done: "Le richieste selezionate, sono state contrassegnate correttamente come 'Inviate'",
					error:
						"Impossibile contrassegnare le richieste selezionate, riprova più tardi",
				},
			},
		},
		import_queues: {
			name: "Importazione messaggio |||| Importazione messaggi",
			fields: {
				q: "Chiave di ricerca",
				bot_id: "Bot",
				source_id: "Fonte",
				name: "Nome",
				urls: "Lista url",
				color: "Colore del font di default",
				bg_color: "Colore di sfondo",
				hashtags: "Lista hashtags",
				template: "Immagine template",
				caption: "Testo del messaggio",
				image_coords: "Coordinate canvas immagine",
				image_size: "Dimensione canvas immagine",
				price_coords: "Coordinate canvas prezzo",
				price_size: "Dimensione canvas prezzo",
				price_font: "Font del prezzo",
				price_color: "Colore del prezzo",
				price_original_coords: "Coordinate canvas prezzo originale",
				price_original_size: "Dimensione canvas prezzo originale",
				price_original_font: "Font del prezzo originale",
				price_original_color: "Colore del prezzo originale",
				statuses: {
					pending: "In attesa",
					processing: "In lavorazione",
					success: "Messaggio creato",
					error: "Impossibile creare messaggio",
				},
				created: "Creato il",
				modified: "Ultima modifica",
			},
			helpers: {
				name: "Il nome interno dell'importazione, ti sarà utile per riutilizzare questo set di impostazioni e poterlo duplicare per utilizzi futuri",
				urls: "Lista di URL della fonte scelta, puoi inserire più di un valore mettendoli uno sotto l'altro nella casella di testo qui sopra",
				color:
					"Colore del font di default che verrà usato per i testi del template, se non se ne specifica uno di default",
				bg_color: "Il colore di sfondo che verrà applicato sotto il template",
				hashtags: "Lista di hashtags che appariranno nel messaggio su telegram",
				template:
					"Immagine template che verrà creata con immagine e prezzo del prodotto importato dagli URL. Se non selezioni un'immagine template il messaggio verrà creato con l'immagine originale del prodotto.",
				caption:
					"Utilizza le seguenti keyword per inserire valori dinamici all'interno del messaggio: {source_name}, {source_content}, {source_price}, {source_price_original}, {url}",
				image_coords:
					"Coordinate del punto dove vuoi inserire il canvas dell'immagine prodotto nel template selezionato",
				image_size:
					"Dimensioni separate da virgola, es: larghezza,altezza -> 100,200",
				price_coords:
					"Coordinate del punto dove vuoi inserire il canvas del prezzo del prodotto nel template selezionato",
				price_size:
					"Dimensioni separate da virgola, es: larghezza, altezza -> 100,200",
				price_font: "Il font che verrà usato nel prezzo",
				price_color: "Il colore che verrà usato nel font del prezzo",
				price_original_coords:
					"Coordinate del punto dove vuoi inserire il canvas del prezzo originale del prodotto nel template selezionato",
				price_original_size:
					"Dimensioni separate da virgola, es: larghezza, altezza -> 100,200",
				price_original_font: "Il font che verrà usato nel prezzo originale",
				price_original_color:
					"Il colore che verrà usato nel font del prezzo originale",
			},
			buttons: {
				view_messages: "Visualizza messaggi creati",
			},
			actions: {
				save_preview: "Anteprima",
				save_show_messages: "Salva e visualizza messaggi",
			},
		},
		updates: {
			name: "Aggiornamento |||| Aggiornamenti",
			fields: {
				type: "Tipologia",
				status: "Status",
				"status.failed": "Fallito",
				"status.processed": "Processato",
				created: "Creato il",
				created_start: "Creato dal",
				created_end: "Fino al",
				"data.chat.title": "Chat",
				payload: "Ricerca",
			},
		},
	},
};

const messages = merge(italianMessages, standardMessages, appMessages);
messages.resources.recycler = messages.resources.explorer;

export default messages;
