// in ./StatusButton.js
import {
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	TextField,
} from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";
import SendIcon from "@material-ui/icons/Send";
import { get } from "lodash";
import React, { Fragment, useState } from "react";
import {
	Button,
	useListContext,
	useMutation,
	useNotify,
	useRedirect,
	useTranslate,
	useUnselectAll,
} from "react-admin";
import { GENERIC } from "../../dataProvider";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		justifyContent: "start",
		flexWrap: "wrap",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
}));

const ReplyButton = ({
	basePath,
	crudUpdateMany,
	resource,
	selectedIds,
	...props
}) => {
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");

	const handleClose = () => {
		setOpen(false);
	};

	const handleClick = () => {
		setOpen(true);
	};

	const { data: records } = useListContext();

	const handleChange = (e) => setMessage(e.target.value);
	const translate = useTranslate();
	const notify = useNotify();
	const redirect = useRedirect();
	const classes = useStyles();
	const unselectAll = useUnselectAll();

	const [sendMessage, { loading }] = useMutation(
		{
			type: GENERIC,
			resource: "queues/reply",
			payload: {
				method: "POST",
				data: {
					ids: selectedIds.join(","),
					message,
				},
			},
		},
		{
			onSuccess: ({ data }) => {
				setMessage("");
				handleClose();
				redirect(`/${resource}`);
				unselectAll(resource);
				notify("resources.replies.messages_sent");
			},
		}
	);

	return (
		<Fragment>
			<Button label="resources.replies.buttons.reply" onClick={handleClick}>
				<ReplyIcon />
			</Button>
			<Dialog
				fullWidth
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
					{translate("resources.replies.send_reply")}
				</DialogTitle>
				<DialogContent>
					<div className={classes.root}>
						{selectedIds.map((sId) => (
							<Chip
								key={sId}
								label={get(records, `${sId}.telegram_from_username`, "")}
							/>
						))}
					</div>
				</DialogContent>
				<DialogContent>
					<TextField
						style={{ minWidth: 200 }}
						margin="dense"
						variant="outlined"
						fullWidth
						required
						focused
						autoFocus
						value={message}
						onChange={handleChange}
						multiline
						minRows={5}
						maxRows={10}
						label={translate("resources.messages.name", 1)}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="primary"
						disabled={loading}
						label="ra.action.undo"
					/>
					<Button
						onClick={sendMessage}
						disabled={loading || !message}
						color="primary"
						label="ra.action.send"
						variant="contained"
					>
						<SendIcon />
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};

export default ReplyButton;
