import React from "react";
import { NullableBooleanInput, ReferenceInput, SelectInput } from "react-admin";
import Filters from "../components/form/Filters";
const PredictionFilters = (props) => (
	<Filters {...props}>
		<ReferenceInput source="unlock_id" reference="unlocks" alwaysOn>
			<SelectInput optionText="name" />
		</ReferenceInput>
		<NullableBooleanInput source="payloaded" alwaysOn />
		<NullableBooleanInput source="sent" alwaysOn />
	</Filters>
);
export default PredictionFilters;
