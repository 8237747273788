import { Chip } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { translate } from "react-admin";
import { compose } from "recompose";
import statuses from "./statuses";

const StatusField = ({ component = "p", translate, source, record }) => {
	let status = statuses.find((s) => s.id === get(record, source));
	if (!status) {
		return null;
	}
	const StatusIcon = get(status, "icon");
	return (
		<Chip
			style={{
				backgroundColor: get(status, "color", ""),
				color: "white",
			}}
			component={component}
			icon={StatusIcon ? <StatusIcon style={{ color: "white" }} /> : null}
			label={translate(`resources.bots.fields.status.${get(record, source)}`)}
		/>
	);
};

export default compose(translate)(StatusField);
