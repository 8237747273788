import React, { useMemo } from 'react';

import { SelectInput } from 'react-admin';

import { useBotContext } from '../../contexts/BotContext';

const BotInput = (props) => {
	const { bots } = useBotContext();
	const choices = useMemo(() => Object.values(bots).map((bot) => bot), [bots]);
	const defaultValue = useMemo(
		() => (choices.length === 1 ? choices[0].id : null),
		[choices]
	);
	return (
		<SelectInput
			disabled={!!defaultValue}
			{...props}
			optionText="name"
			choices={choices}
			defaultValue={defaultValue}
		/>
	);
};

export default BotInput;
