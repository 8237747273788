import React from "react";
import Filters from "../components/form/Filters";
import { TypeInput } from "./Type";

const TargetFilters = (props) => (
	<Filters {...props}>
		<TypeInput source="type" alwaysOn />
	</Filters>
);
export default TargetFilters;
