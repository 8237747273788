import React from 'react';

import {
  BooleanInput,
  NumberInput,
  TextInput,
  useInput,
  useTranslate,
} from 'react-admin';

import ImageInput from '../../components/inputs/ImageInput';
import TelegramMessageInput from '../../components/inputs/TelegramMessageInput';
import { rules } from '../../message_types/index';
import InlineKeyboardMarkupInput from './InlineKeyboardMarkupInput';

const fileAcceptByComponent = (component) => {
	switch (component) {
		case "AudioInput":
			return "audio/*";
		case "VideoInput":
			return "video/*";
		case "DocumentInput":
			return "document/*";
		case "MediaInput":
			return "video/*,image/*";
		default:
			return "*";
	}
};

const SwitchInput = ({ field, index, ...props }) => {
	const { source } = props;
	const translate = useTranslate();
	const defaultValue = field.default || "";
	const {
		input: { value },
	} = useInput(props);

	const getLabel = () =>
		`resources.${source.replace(`[${index}]`, "").replace("value", "")}fields.${
			field.name
		}`;
	const validate = () => {
		var validateFns = [];
		if (field.rules) {
			field.rules.forEach((fieldRule) => {
				let ruleFn = rules.find((rule) => rule.id === fieldRule);
				if (typeof ruleFn !== "undefined") {
					validateFns.push(ruleFn.fn());
				}
			});
		}
		return validateFns;
	};

	switch (field.component) {
		case "HiddenInput":
			return (
				<div style={{ display: "none" }}>
					<TextInput
						{...props}
						label={false}
						type="hidden"
						initialValue={defaultValue}
						fullWidth
					/>
				</div>
			);
		case "TextInput":
			return (
				<TextInput
					{...props}
					label={getLabel()}
					validate={validate()}
					initialValue={defaultValue}
					fullWidth
				/>
			);
		case "BooleanInput":
			return (
				<BooleanInput
					{...props}
					label={getLabel()}
					validate={validate()}
					initialValue={defaultValue === "true" ? true : false}
					fullWidth
				/>
			);
		case "NumberInput":
			return (
				<NumberInput
					{...props}
					label={getLabel()}
					validate={validate()}
					initialValue={defaultValue}
					fullWidth
				/>
			);
		case "ImageInput":
			return (
				<ImageInput
					{...props}
					label={getLabel()}
					multiple={false}
					source={`${source}_single_media`}
					accept="image/*"
					validate={validate()}
					fullWidth
				/>
			);
		case "FileInput":
		case "AudioInput":
		case "VideoInput":
		case "DocumentInput":
		case "MediaInput":
			return (
				<ImageInput
					{...props}
					label={getLabel()}
					source={`${source}_${
						field.component === "MediaInput" ? "multiple_media" : "single_media"
					}`}
					validate={validate()}
					accept={fileAcceptByComponent(field.component)}
					fullWidth
					multiple={field.component === "MediaInput"}
				/>
			);
		case "RichTextInput":
			return (
				<TelegramMessageInput
					{...props}
					label={getLabel()}
					validate={validate()}
					toolbar={[
						["bold", "italic", "underline", "strike", "code", "link", "clean"],
					]}
					minRows={5}
					fullWidth
					variant="filled"
					helperText={translate(
						"resources.messages_message_type_fields.helpers.caption",
						{
							count: value.length,
							limit: 1024,
						}
					)}
				/>
			);
		case "MarkdownInput":
			return (
				<TelegramMessageInput
					{...props}
					label={getLabel()}
					validate={validate()}
					toolbar={[
						["bold", "italic", "underline", "strike", "code", "link", "clean"],
					]}
					minRows={5}
					fullWidth
					variant="filled"
					helperText={translate(
						"resources.messages_message_type_fields.helpers.caption",
						{
							count: value.length,
							limit: 1024,
						}
					)}
				/>
			);
		case "InlineKeyboardMarkupInput":
			return (
				<InlineKeyboardMarkupInput
					{...props}
					label={getLabel()}
					source={`${source}_json`}
					fullWidth
				/>
			);
		default:
			return (
				<TextInput
					{...props}
					label={getLabel()}
					validate={validate()}
					initialValue={defaultValue}
					fullWidth
				/>
			);
	}
};

export default SwitchInput;
