import React from 'react';

import { BulkDeleteWithConfirmButton } from 'react-admin';

import SetAllBotsButton from './buttons/SetAllBotsButton';
import UnsetAllBotsButton from './buttons/UnsetAllBotsButton';

const BulkActionButtons = (props) => {
	return (
		<>
			<SetAllBotsButton {...props} />
			<UnsetAllBotsButton {...props} />
			<BulkDeleteWithConfirmButton {...props} />
		</>
	);
};

export default BulkActionButtons;
