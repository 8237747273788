import { IconButton, Tooltip } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { useRedirect, useTranslate } from "react-admin";
import { ForumIcon } from "../icons";

const ViewMessagesButton = ({
	basePath = "",
	label = "resources.import_queues.buttons.view_messages",
	record,
	...rest
}) => {
	const redirect = useRedirect();
	const translate = useTranslate();
	const handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		redirect(
			`/messages?filter=${JSON.stringify({
				import_queue_id: get(record, "id"),
			})}`
		);
	};

	return (
		<Tooltip title={translate(label)}>
			<IconButton color="primary" variant="text" onClick={handleClick}>
				<ForumIcon fontSize="small" />
			</IconButton>
		</Tooltip>
	);
};

export default ViewMessagesButton;
