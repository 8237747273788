import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import { get } from "lodash";
import React from "react";
import {
	DeleteButton,
	SaveButton,
	Toolbar as ReactAdminToolbar,
	useRedirect,
	useTranslate,
} from "react-admin";

const useStyles = makeStyles((theme) => ({
	toolbar: {
		paddingLeft: theme.spacing(2),
		justifyContent: "space-between",
		display: "flex",
	},
	button: {
		marginRight: theme.spacing(1),
		overflow: "hidden",
	},
}));

const Toolbar = ({ ...props }) => {
	const translate = useTranslate();
	const classes = useStyles();
	const redirect = useRedirect();
	const { record } = props;
	const handleOnSuccess = () => {
		redirect(
			"create",
			"/queues",
			null,
			{},
			{
				record: {
					bot_id: get(record, "bot_id"),
					messages: [get(record, "id")],
				},
			}
		);
	};
	return (
		<ReactAdminToolbar className={classes.toolbar} {...props}>
			<SaveButton
				className={classes.button}
				redirect="list"
				submitOnEnter={true}
				disabled={props.pristine}
			/>
			<SaveButton
				variant="text"
				className={classes.button}
				submitOnEnter={false}
				color="primary"
				icon={<SendIcon />}
				label={translate(`resources.messages.actions.save_send`)}
				onSuccess={handleOnSuccess}
			/>
			<DeleteButton undoable={false} />
		</ReactAdminToolbar>
	);
};

export default Toolbar;
