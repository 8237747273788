import React from "react";

import {
	Button,
	useGetList,
	useMutation,
	useNotify,
	useRefresh,
	useTranslate,
	useUnselectAll,
} from "react-admin";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	MenuItem,
	TextField,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/CheckCircle";
import UndoIcon from "@material-ui/icons/ErrorOutline";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { GENERIC } from "../../dataProvider";

const useStyles = makeStyles((theme) => ({
	select: {
		minWidth: "100%",
	},
}));

const CloneAllButton = ({ selectedIds }) => {
	const classes = useStyles();
	const [botId, setBotId] = React.useState("");
	const translate = useTranslate();
	const unselectAll = useUnselectAll("messages");
	const notify = useNotify();
	const refresh = useRefresh();
	const [open, setOpen] = React.useState(false);
	const [duplicate, { loading: duplicateLoading }] = useMutation(
		{
			type: GENERIC,
			resource: "messages/duplicate-all",
			payload: {
				data: { selected_messages: selectedIds, bot_id: botId },
				method: "POST",
			},
		},
		{
			onSuccess: ({ data }) => {
				setOpen(false);
				notify(
					translate("resources.messages.actions.cloned_all_success", {
						cloned: data?.cloned || 0,
						errors: data?.errors || 0,
					}),
					"info",
					{}
				);
				unselectAll();
				refresh();
			},
			onFailure: ({ data }) => {
				notify(
					translate("resources.messages.actions.cloned_all_error", {
						smart_count: data?.errors || 0,
					}),
					"warning",
					{}
				);
				setOpen(false);
			},
		}
	);

	const { data: bots, loading } = useGetList("bots", {
		pagination: { page: 1, perPage: 100 },
		sort: { field: "name", order: "ASC" },
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
	};

	const handleUpdate = () => {
		duplicate();
	};

	if (!loading && Object.keys(bots).length === 0) {
		return null;
	}

	return (
		<>
			<Button
				color="secondary"
				onClick={handleClickOpen}
				label={translate("resources.messages.actions.clone_all")}
				disabled={loading}
			>
				<FileCopyIcon />
			</Button>
			<Dialog
				onClose={handleClose}
				aria-labelledby="simple-dialog-title"
				open={open}
			>
				<DialogTitle id="simple-dialog-title">
					{translate("resources.messages.actions.clone_all_title", {
						smart_count: selectedIds?.length,
					})}
				</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						className={classes.select}
						margin="dense"
						id="clone-all-bot-id"
						select
						label={translate("resources.messages.fields.bot_id")}
						value={botId}
						onChange={(e) => setBotId(e.target.value)}
						variant="outlined"
						helperText={translate("resources.messages.actions.clone_all_help")}
					>
						<MenuItem value="">
							<em>{translate("ra.boolean.null")}</em>
						</MenuItem>
						{Object.values(bots).map((bot) => (
							<MenuItem value={bot.id} key={bot.id}>
								{bot.name}
							</MenuItem>
						))}
					</TextField>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						// disabled={updateLoading}
						label={translate("ra.action.undo")}
						color="default"
					>
						<UndoIcon />
					</Button>
					<Button
						onClick={handleUpdate}
						disabled={!botId || duplicateLoading}
						label={translate("resources.messages.actions.clone_all")}
					>
						<DoneIcon />
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default CloneAllButton;
