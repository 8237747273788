import React from 'react';

import polyglotI18nProvider from 'ra-i18n-polyglot';
import {
  errorsReducer,
  errorsSaga,
} from 'ra-remote-validator';
import {
  Admin,
  Resource,
} from 'react-admin';
import { compose } from 'recompose';

import addUploadFeature from './addUploadFeature';
import authProvider from './authProvider';
import bots from './bots';
import CustomLoginPage from './customLoginPage';
import dataProvider from './dataProvider';
import folders from './folders';
import italianMessages from './i18n/it';
import importQueues from './import-queues';
import Layout from './Layout';
import message_types from './message_types';
import messages from './messages';
import queues from './queues';
import replies from './replies';
import roles from './roles';
import targets from './targets';
import theme from './theme';
import unlock_requests from './unlock_requests';
import unlocks from './unlocks';
import updates from './updates';
import useI18nCatcher from './useI18nCatcher';
import users from './users';

const i18nMessages = {
	it: italianMessages,
};
const i18nProvider = polyglotI18nProvider(
	(locale) => i18nMessages[locale] || italianMessages
);
const dp = compose(addUploadFeature)(dataProvider);

const App = () => {
	const sidebarIsOpen = JSON.parse(localStorage.getItem("sidebar_open"));
	const initialState = {
		admin: {
			ui: {
				sidebarOpen: sidebarIsOpen === null ? true : sidebarIsOpen,
				viewVersion: 0,
			},
		},
	};
	useI18nCatcher();
	return (
		<Admin
			initialState={initialState}
			customSagas={[errorsSaga]}
			customReducers={{
				errors: errorsReducer,
			}}
			dataProvider={dp}
			authProvider={authProvider}
			i18nProvider={i18nProvider}
			loginPage={CustomLoginPage}
			theme={theme}
			layout={Layout}
		>
			<Resource name="messages" {...messages} />
			<Resource name="callbacks" />
			<Resource name="sources" />
			<Resource name="fonts" />
			<Resource name="queues" {...queues} />
			<Resource name="replies" {...replies} />
			<Resource name="bots" {...bots} />
			<Resource name="import_queues" {...importQueues} />
			<Resource name="unlocks" {...unlocks} />
			<Resource name="unlock_requests" {...unlock_requests} />
			<Resource name="targets" {...targets} />
			<Resource name="folders" {...folders} />
			<Resource name="updates" {...updates} />
			<Resource name="message_types" {...message_types} />
			<Resource name="users" {...users} />
			<Resource name="roles" {...roles} />
		</Admin>
	);
};

export default App;
