import LogoutIcon from "@material-ui/icons/ExitToAppOutlined";
import React, { forwardRef } from "react";
import {
	MenuItemLink,
	useAuthProvider,
	useNotify,
	useRedirect,
	UserMenu as RaUserMenu,
	useTranslate,
} from "react-admin";
import useImpersonate from "./useImpersonate";

const ConfigurationMenu = forwardRef((props, ref) => {
	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();
	const impersonate = useImpersonate();
	const authProvider = useAuthProvider();
	const handleImpersonateLogout = (e) => {
		e.preventDefault();
		e.stopPropagation();
		authProvider
			.stopImpersonate()
			.then(() => {
				redirect("/");
				setTimeout(() => document.location.reload(), 100);
			})
			.catch((error) => notify(error, "warning"));
	};
	return [
		impersonate && (
			<MenuItemLink
				key={2}
				ref={ref}
				to="/impersonate"
				leftIcon={<LogoutIcon />}
				primaryText={translate("ra.auth.impersonate_logout")}
				onClick={handleImpersonateLogout}
			/>
		),
	];
});

const UserMenu = (props) => {
	return (
		<RaUserMenu {...props}>
			<ConfigurationMenu />
		</RaUserMenu>
	);
};

export default UserMenu;
