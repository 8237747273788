import React from 'react';

import RemoteErrorsInterceptor from 'ra-remote-validator';
import {
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import {
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import BotInput from '../components/inputs/BotInput';
import CaptionInput from './inputs/CaptionInput';
import TemplateInput from './inputs/TemplateInput';
import statuses from './statuses';
import Toolbar from './Toolbar';

const Form = ({
	create = false,
	dispatch,
	validate,
	errors,
	permissions,
	...props
}) => {
	const theme = useTheme();
	const toolbar = !create ? { toolbar: <Toolbar /> } : {};
	const validProps = { variant: "outlined" };
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const spacing = 1;
	return (
		<SimpleForm
			{...props}
			variant="outlined"
			redirect="list"
			validate={validate}
			initialValues={{
				price_original_font: "MyriadPro-Regular.otf",
				price_font: "Kepler-296.otf",
				bg_color: "#FFFFFF",
				color: "#000000",
			}}
			{...toolbar}
		>
			<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
			<BotInput
				{...validProps}
				source="bot_id"
				validate={required()}
				style={{ marginBottom: theme.spacing(spacing) }}
				fullWidth
			/>
			<FormDataConsumer>
				{({ formData, ...rest }) => {
					if (!formData.bot_id) {
						return null;
					}
					return (
						<Grid container spacing={isSmall ? 0 : 1}>
							<Grid item xs={12} md={12}>
								<ReferenceInput
									{...validProps}
									reference="sources"
									source="source_id"
									fullWidth
									resource={rest.resource}
									validate={required()}
									helperText="resources.import_queues.helpers.source_id"
								>
									<SelectInput />
								</ReferenceInput>
							</Grid>
							<Grid item xs={12} md={12}>
								<TextInput
									{...validProps}
									resource={rest.resource}
									validate={required()}
									source="name"
									helperText="resources.import_queues.helpers.name"
									fullWidth
								/>
							</Grid>
							{!create && (
								<Grid item xs={12} md={12}>
									<SelectInput
										{...validProps}
										source="status"
										choices={statuses}
										resource={rest.resource}
										validate={required()}
										defaultValue="pending"
										fullWidth
									/>
								</Grid>
							)}
							<Grid item xs={12} md={12}>
								<TextInput
									{...validProps}
									source="urls"
									multiline
									resource={rest.resource}
									validate={required()}
									fullWidth
									minRows={10}
									helperText="resources.import_queues.helpers.urls"
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<CaptionInput
									{...validProps}
									source="caption"
									minRows={5}
									resource={rest.resource}
									validate={required()}
									fullWidth
									helperText="resources.import_queues.helpers.caption"
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<TemplateInput
									{...validProps}
									source="template_media"
									resource={rest.resource}
									fullWidth
									helperText="resources.import_queues.helpers.template"
								/>
							</Grid>
						</Grid>
					);
				}}
			</FormDataConsumer>
		</SimpleForm>
	);
};

export default Form;
