import React from "react";

import RemoteErrorsInteceptor, { withErrors } from "ra-remote-validator";
import {
	BooleanInput,
	FormDataConsumer,
	NumberInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import { compose } from "recompose";

import { InputAdornment } from "@material-ui/core";

import Create from "../components/form/Create";
import BotInput from "../components/inputs/BotInput";
import { types } from "../targets/Type";

const TargetCreate = ({ dispatch, validate, errors, ...props }) => {
	return (
		<Create {...props}>
			<SimpleForm variant="outlined" redirect="edit" validate={validate}>
				<RemoteErrorsInteceptor errors={errors} dispatch={dispatch} />
				<BotInput source="bot_id" validate={required()} fullWidth />
				<SelectInput source="type" choices={types} fullWidth />
				<TextInput validate={required()} source="name" fullWidth />
				<TextInput validate={required()} source="telegram_id" fullWidth />
				<TextInput validate={required()} source="telegram_username" fullWidth />
				<TextInput source="telegram_firstname" fullWidth />
				<TextInput source="telegram_lastname" fullWidth />
				<FormDataConsumer>
					{({ formData, ...rest }) => {
						if (
							formData?.type !== "channel" &&
							formData?.type !== "supergroup"
						) {
							return null;
						}
						return (
							<>
								<BooleanInput
									{...rest}
									source="auto_accept_join_requests"
									fullWidth
									className=""
									helperText="resources.targets.fields.auto_accept_join_requests.help"
								/>
								{formData?.auto_accept_join_requests && (
									<NumberInput
										{...rest}
										source="auto_accept_join_requests_delay"
										fullWidth={false}
										className
										helperText="resources.targets.fields.auto_accept_join_requests_delay.help"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">min</InputAdornment>
											),
										}}
									/>
								)}
							</>
						);
					}}
				</FormDataConsumer>
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(TargetCreate);
