import { Chip } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { get } from "lodash";
import React from "react";
import { useNotify, useTranslate } from "react-admin";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CommandField = ({ record, source, basePath, ...props }) => {
	const value = get(record, source, "-");
	const notify = useNotify();
	const translate = useTranslate();
	return (
		<CopyToClipboard
			text={value}
			onCopy={() => notify(translate("ra.action.content_copied"))}
		>
			<Chip {...props} avatar={<FileCopyIcon />} label={value} />
		</CopyToClipboard>
	);
};

export default CommandField;
