import { InputAdornment } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { Button, TextInput, useInput, useNotify } from "react-admin";
import CopyToClipboard from "react-copy-to-clipboard";

const ClipboardTextInput = ({ source, ...props }) => {
	const notify = useNotify();
	const { input } = useInput(source);
	const value = get(input, `value.${source}`);
	return (
		<TextInput
			{...props}
			source={source}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<CopyToClipboard
							text={value}
							onCopy={() => notify("ra.action.content_copied")}
							disabled={!value}
						>
							<Button onClick={() => {}} label="ra.action.copy" />
						</CopyToClipboard>
					</InputAdornment>
				),
			}}
		/>
	);
};

export default ClipboardTextInput;
