import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import { required, SimpleForm, TextInput } from "react-admin";
import { compose } from "recompose";
import Edit from "../components/form/Edit";

const RoleEdit = ({ dispatch, validate, errors, ...props }) => (
	<Edit title="name" {...props}>
		<SimpleForm variant="outlined" redirect="list" validate={validate}>
			<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
			<TextInput validate={required()} source="code" fullWidth />
			<TextInput validate={required()} source="name" fullWidth />
		</SimpleForm>
	</Edit>
);
export default compose(withErrors)(RoleEdit);
