import { green, red } from "@material-ui/core/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

export default [
	{
		id: "connected",
		name: "resources.bots.fields.status.connected",
		color: green[500],
		Icon: CheckCircleIcon,
	},
	{
		id: "disconnected",
		name: "resources.bots.fields.status.disconnected",
		color: red[500],
		Icon: ErrorIcon,
	},
];
