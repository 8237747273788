import React from 'react';

import { get } from 'lodash';
import {
  Button,
  DeleteButton,
  FormDataConsumer,
  SaveButton,
  Toolbar as ReactAdminToolbar,
  useTranslate,
} from 'react-admin';
import { useHistory } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import SafeTag from '../components/SafeTag';
import { API_URL } from '../config';

const styles = (theme) => ({
	toolbar: {
		paddingLeft: theme.spacing(2),
		justifyContent: "space-between",
		display: "flex",
	},
	button: {
		marginRight: theme.spacing(1),
		overflow: "hidden",
	},
});

const Toolbar = ({ classes, ...props }) => {
	const translate = useTranslate();
	const history = useHistory();
	const search = get(history, "location.search", "");
	const request = Object.fromEntries(
		new URLSearchParams(search.replace("?", ""))
	);
	const connected = get(request, "connected", null);
	const disconnected = get(request, "disconnected", null);
	return (
		<ReactAdminToolbar className={classes.toolbar} {...props}>
			<SaveButton
				className={classes.button}
				redirect="list"
				submitOnEnter={true}
				disabled={props.pristine}
			/>
			<SafeTag>
				<FormDataConsumer>
					{({ formData, ...rest }) => (
						<SafeTag>
							{formData.status === "disconnected" ? (
								<Button
									className={classes.button}
									href={`${API_URL}/bots/register/${
										formData.id
									}?redirect_url=${encodeURIComponent(
										document.location.href.replace(search, "")
									)}`}
									color="primary"
									label={translate(`resources.bots.actions.register`)}
								>
									{connected ? (
										connected === "false" ? (
											<ErrorIcon />
										) : (
											<CheckCircleIcon />
										)
									) : (
										<LockOpenIcon />
									)}
								</Button>
							) : (
								<Button
									className={classes.button}
									href={`${API_URL}/bots/unregister/${
										formData.id
									}?redirect_url=${encodeURIComponent(
										document.location.href.replace(search, "")
									)}`}
									color="secondary"
									label={translate(`resources.bots.actions.unregister`)}
								>
									{disconnected ? (
										disconnected === "false" ? (
											<ErrorIcon />
										) : (
											<CheckCircleIcon />
										)
									) : (
										<LockIcon />
									)}
								</Button>
							)}
						</SafeTag>
					)}
				</FormDataConsumer>
			</SafeTag>
			<DeleteButton undoable={false} />
		</ReactAdminToolbar>
	);
};

export default withStyles(styles)(Toolbar);
