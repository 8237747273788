import { LinearProgress } from "@material-ui/core";
import { green, yellow } from "@material-ui/core/colors";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import React from "react";
import { useQueryWithStore, useTranslate } from "react-admin";
import RequestsButton from "./RequestsButton";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
	payloaded: {
		color: yellow[900],
	},
	sent: {
		color: green[800],
	},
}));

const Expand = ({ id, record, resource }) => {
	const classes = useStyles();
	const translate = useTranslate();
	const { data: insights } = useQueryWithStore({
		type: "getOne",
		resource: `unlocks/insights/${record.id}`,
		payload: {},
	});
	if (!insights) {
		return <LinearProgress />;
	}
	return (
		<React.Fragment>
			<List
				subheader={<ListSubheader>Insights</ListSubheader>}
				className={classes.root}
			>
				{Object.keys(insights)
					.filter((key) => key !== "id")
					.map((key) => (
						<ListItem key={key} className={get(classes, key, "")}>
							<ListItemText
								primary={translate(`resources.unlocks.insights.${key}`)}
							/>
							<ListItemSecondaryAction>
								{get(insights, key, 0)}
							</ListItemSecondaryAction>
						</ListItem>
					))}
				<ListItem>
					<RequestsButton record={record} resource={resource} />
				</ListItem>
			</List>
		</React.Fragment>
	);
};

export default Expand;
