import { get } from "lodash";
import React from "react";
import { Button, useRedirect } from "react-admin";
import { ListIcon } from "../icons";

const RequestsButton = ({
	basePath = "",
	label = "resources.unlocks.actions.requests",
	record,
	...rest
}) => {
	const redirect = useRedirect();
	const handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		redirect(
			`/unlock_requests?filter=${JSON.stringify({
				unlock_id: get(record, "id"),
			})}`
		);
	};

	return (
		<Button
			color="secondary"
			variant="contained"
			onClick={handleClick}
			label={label}
		>
			<ListIcon fontSize="small" />
		</Button>
	);
};

export default RequestsButton;
