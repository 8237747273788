import { Grid } from "@material-ui/core";
import React from "react";
import { useInput } from "react-admin";
import MessageFieldsInput from "./MessageFieldsInput";

const MessageTypesInput = (props) => {
	const {
		input: { value },
	} = useInput(props);

	return (
		<Grid item xs={12} md={12}>
			<MessageFieldsInput
				{...props}
				key={value}
				source="messages_message_type_fields"
				messageTypeId={value}
			/>
		</Grid>
	);
};

export default MessageTypesInput;
