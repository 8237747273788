import React from 'react';

import {
  useRecordContext,
  useRedirect,
} from 'react-admin';

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  ImageList,
  ImageListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
	root: {
		maxWidth: 300,
		marginTop: 10,
		marginBottom: 10,
	},
	header: {
		whiteSpace: "break-spaces",
	},
	media: {
		height: 300,
	},
	actions: {
		paddingTop: 0,
	},
	content: {
		"& p": {
			padding: 0,
			margin: 0,
		},
	},
});

const Expand = () => {
	const classes = useStyles();
	const record = useRecordContext();
	const id = record?.id;
	const name = record?.name;
	const image = record?.image_preview;
	const text = record?.text_preview;
	const command = record?.command;
	const botUsername = record?.bot?.username;
	const buttons = record?.buttons_preview;
	const redirect = useRedirect();

	return (
		<Card className={classes.root} elevation={1}>
			<CardActionArea onClick={() => redirect("edit", "/messages", id)}>
				<CardHeader
					className={classes.header}
					title={<Typography style={{ fontWeight: "bold" }}>{name}</Typography>}
					subheader={
						<Box style={{ display: "flex", justifyContent: "space-between" }}>
							{command && (
								<Typography component="span" variant="caption">
									{"/" + command}
								</Typography>
							)}
							<Typography component="span" variant="caption">
								{"@" + botUsername}
							</Typography>
						</Box>
					}
				/>
				{image && !Array.isArray(image) && !image.includes("No+image") && (
					<CardMedia className={classes.media} image={image} title={name} />
				)}
				{image && Array.isArray(image) && (
					<ImageList
						rowHeight={130}
						className={classes.imageList}
						cols={2}
						gap={0}
					>
						{image.map((item, index) => (
							<ImageListItem key={index} cols={1}>
								<img src={item.src} alt={name} />
							</ImageListItem>
						))}
					</ImageList>
				)}
				<Divider />
				<CardContent>
					<div
						dangerouslySetInnerHTML={{ __html: text }}
						style={{ whiteSpace: "break-spaces" }}
						className={classes.content}
					/>
				</CardContent>
				{buttons && buttons?.length > 0 && (
					<>
						{buttons?.map((button, index) => (
							<CardActions key={index} className={classes.actions}>
								<Button
									size="small"
									color="default"
									variant="contained"
									style={{ width: "100%" }}
									disableElevation
								>
									{button.text}
								</Button>
							</CardActions>
						))}
					</>
				)}
			</CardActionArea>
		</Card>
	);
};

export default Expand;
