import React from "react";
import { BooleanInput } from "react-admin";
import Filters from "../components/form/Filters";

const ReplyFilters = (props) => (
	<Filters {...props}>
		<BooleanInput source="exclude_start" alwaysOn fullWidth />
	</Filters>
);
export default ReplyFilters;
