import React from 'react';

import {
  ChipField,
  SelectInput,
  useTranslate,
} from 'react-admin';

import { makeStyles } from '@material-ui/core';

export const statuses = [
	{ id: "failed", name: "resources.updates.fields.status.failed" },
	{ id: "processed", name: "resources.updates.fields.status.processed" },
];

const useStyles = makeStyles((theme) => ({
	failed: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.error.contrastText,
		fontWeight: "bold",
	},
	processed: {
		backgroundColor: theme.palette.success.main,
		color: "white",
		fontWeight: "bold",
	},
}));

export const StatusField = ({ record, source, ...props }) => {
	const classes = useStyles();
	const value = record[source];
	const translate = useTranslate();
	return (
		<ChipField
			record={{
				...record,
				[source]: translate(`resources.updates.fields.status.${value}`),
			}}
			source={source}
			{...props}
			className={classes?.[value]}
		/>
	);
};

export const StatusInput = ({ record, source, ...props }) => {
	return <SelectInput source={source} choices={statuses} {...props} />;
};
