import React from 'react';

import {
  useRedirect,
  useResourceContext,
  useTranslate,
} from 'react-admin';

import {
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { useBotContext } from '../../contexts/BotContext';
import { AndroidIcon } from '../../icons';
import List from './List';

const useStyles = makeStyles(
	(theme) => ({
		root: {
			textAlign: "center",
		},
		message: {
			textAlign: "center",
			opacity: theme.palette.type === "light" ? 0.5 : 0.8,
			margin: "0 1em",
			color:
				theme.palette.type === "light" ? "inherit" : theme.palette.text.primary,
		},
		icon: {
			width: "9em",
			height: "9em",
		},
		toolbar: {
			textAlign: "center",
			marginTop: "2em",
		},
	}),
	{ name: "RaEmpty" }
);

const ListBot = ({ children, ...props }) => {
	const translate = useTranslate();
	const classes = useStyles(props);
	const { botIds } = useBotContext();
	const redirect = useRedirect();
	const resource = useResourceContext(props);
	const filter = {
		...props?.filter,
		bot_ids: botIds.join(","),
	};

	if (!botIds || botIds.length === 0) {
		return (
			<div className={classes.root}>
				<div className={classes.message}>
					<AndroidIcon className={classes.icon} />
					<Typography variant="h4" paragraph>
						{translate("resources.bots.actions.please_select")}
					</Typography>
				</div>
				<Button
					variant="contained"
					color="primary"
					aria-label={translate("resources.bots.actions.select")}
					onClick={() => redirect(`/bots?back=${resource}`)}
					startIcon={<AndroidIcon />}
				>
					{translate("resources.bots.actions.select")}
				</Button>
			</div>
		);
	}

	return (
		<List
			{...props}
			filter={filter}
			filterDefaultValues={{
				...props?.filterDefaultValues,
				...filter,
			}}
		>
			{children}
		</List>
	);
};

export default ListBot;
