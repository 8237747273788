import { useMediaQuery } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
	createStyles,
	createTheme,
	withStyles,
} from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import PropTypes from "prop-types";
import { Menu } from "ra-friendsofbabba";
import React, { createElement, useEffect, useRef, useState } from "react";
import { defaultTheme, Notification, useToggleSidebar } from "react-admin";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import AppBar from "./AppBar";
import HelperAlert from "./components/HelperAlert";
import { BotContext } from "./contexts/BotContext";
import { HelperContext } from "./contexts/HelperContext";
import Sidebar from "./Sidebar";

const styles = (theme) =>
	createStyles({
		root: {
			display: "flex",
		},
		[theme.breakpoints.down("md")]: {
			root: {
				display: "block",
			},
		},
		toolbar: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			padding: theme.spacing(0, 1),
			...theme.mixins.toolbar,
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
			[theme.breakpoints.down("xs")]: {
				padding: theme.spacing(0),
			},
		},
	});

class LayoutWithoutTheme extends React.Component {
	state = { hasError: false, errorMessage: null, errorInfo: null };

	constructor(props) {
		super(props);
		/**
		 * Reset the error state upon navigation
		 *
		 * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
		 */
		props.history.listen(() => {
			if (this.state.hasError) {
				this.setState({ hasError: false });
			}
		});
	}

	componentDidCatch(errorMessage, errorInfo) {
		this.setState({ hasError: true, errorMessage, errorInfo });
	}

	render() {
		const {
			appBar = AppBar,
			children,
			classes,
			error,
			dashboard,
			logout,
			menu = Menu,
			notification = Notification,
			open,
			sidebar = Sidebar,
			title,
			appTitle,
			appSubTitle,
			appVersion,
			drawerWidth,
			location,
		} = this.props;
		const { hasError, errorMessage, errorInfo } = this.state;
		return (
			<HelperContext>
				<BotContext>
					<div className={classes.root}>
						<CssBaseline />
						{createElement(sidebar, {
							open,
							appTitle,
							appSubTitle,
							appVersion,
							drawerWidth,
							children: createElement(menu, {
								open,
								logout,
								hasDashboard: !!dashboard,
								menuConfig: this.props.menu,
							}),
						})}
						<main id="main-content" className={classes.content}>
							{createElement(appBar, {
								title,
								open,
								logout,
								location,
								drawerWidth,
							})}
							<div className={classes.toolbar} />
							<HelperAlert />
							{hasError
								? createElement(error, {
										error: errorMessage,
										errorInfo,
										title,
								  })
								: children}
						</main>
						{createElement(notification)}
					</div>
				</BotContext>
			</HelperContext>
		);
	}
}

const mapStateToProps = (state) => ({
	open: state.admin.ui.sidebarOpen,
});

const EnhancedLayout = compose(
	connect(
		mapStateToProps,
		{} // Avoid connect passing dispatch in props
	),
	withRouter,
	withStyles(styles, { name: "RaLayout" })
)(LayoutWithoutTheme);

const Layout = ({ theme: themeOverride, ...props }) => {
	const themeProp = useRef(themeOverride);
	const [theme, setTheme] = useState(() => createTheme(themeOverride));

	useEffect(() => {
		if (themeProp.current !== themeOverride) {
			themeProp.current = themeOverride;
			setTheme(createTheme(themeOverride));
		}
	}, [themeOverride, themeProp, theme, setTheme]);

	const isSmall = useMediaQuery(theme.breakpoints.down("md"));
	const [sidebarOpen, toggleSidebar] = useToggleSidebar();

	useEffect(() => {
		if (isSmall && typeof toggleSidebar === "function" && sidebarOpen) {
			toggleSidebar();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSmall]);

	useEffect(() => {
		localStorage.setItem("sidebar_open", sidebarOpen);
	}, [sidebarOpen]);

	return (
		<ThemeProvider theme={theme}>
			<EnhancedLayout {...props} />
		</ThemeProvider>
	);
};

Layout.propTypes = {
	theme: PropTypes.object,
	appTitle: PropTypes.string.isRequired,
	appSubTitle: PropTypes.string,
	appVersion: PropTypes.string,
	drawerWidth: PropTypes.number,
};

Layout.defaultProps = {
	theme: defaultTheme,
	appTitle: "React Admin",
	appSubTitle: "Material-UI",
	appVersion: "1.0.0",
	drawerWidth: 240,
};

export default Layout;
