import React from 'react';

import { get } from 'lodash';

import {
  makeStyles,
  Typography,
} from '@material-ui/core';

import SendMessageButton from '../inputs/SendMessageButton';

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "skretch",
	},
	p_root: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
	},
	p: {
		fontWeight: "bold",
		// whiteSpace: "normal",
		maxWidth: 300,
		overflow: "hidden",
	},
	button_root: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "flex-start",
	},
	button: {
		padding: theme.spacing(1),
	},
}));

const NameField = ({ source, record, basePath, emptyText, ...props }) => {
	const value = get(record, source, emptyText);
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.button_root}>
				<SendMessageButton
					record={record}
					variant="contained"
					className={classes.button}
				/>
			</div>
			<div className={classes.p_root}>
				<Typography
					variant={value.length > 50 ? "caption" : "body2"}
					className={classes.p}
				>
					&nbsp;
					{value}
				</Typography>
			</div>
		</div>
	);
};
export default NameField;
