import { withErrors } from "ra-remote-validator";
import React from "react";
import { compose } from "recompose";
import Edit from "../components/form/Edit";
import Form from "./Form";

const ImportQueueEdit = ({ dispatch, validate, errors, ...props }) => (
	<Edit title="name" {...props}>
		<Form validate={validate} errors={errors} dispatch={dispatch} />
	</Edit>
);

export default compose(withErrors)(ImportQueueEdit);
