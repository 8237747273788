import { ReplyAllIcon } from "../icons";
import List from "./List";

export default {
	options: {
		group: "send",
		roles: ["admin", "user", "publisher"],
	},
	icon: ReplyAllIcon,
	list: List,
};
