import React from "react";
import { Edit as RaEdit, useNotify, useRefresh } from "react-admin";
import { useFormStyles } from "../../hooks/useStyles";
import Title from "../Title";

const Edit = ({
	dispatch,
	validate,
	errors,
	permissions,
	children,
	title = "id",
	titlePrefix = "",
	refresh = false,
	...props
}) => {
	const classes = useFormStyles();
	const notify = useNotify();
	const raRefresh = useRefresh();
	const onSuccess = (data) => {
		notify("ra.notification.updated", "info", { smart_count: 1 });
		if (refresh) {
			raRefresh();
		}
	};
	return (
		<RaEdit
			{...props}
			classes={classes}
			title={<Title source={title} prefix={titlePrefix} />}
			mutationMode="pessimistic"
			onSuccess={onSuccess}
		>
			{children}
		</RaEdit>
	);
};

export default Edit;
