import React, { useEffect } from 'react';

import {
  Button,
  useGetList,
  useListContext,
  useRefresh,
  useTranslate,
  useUnselectAll,
  useUpdateMany,
} from 'react-admin';

import {
  alpha,
  Avatar,
  Checkbox,
  Dialog,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/CheckCircle';
import UndoIcon from '@material-ui/icons/ErrorOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FolderIcon from '@material-ui/icons/FolderOpen';

const useStyles = makeStyles((theme) => ({
	avatar: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	button: {
		color: theme.palette.error.main,
		"&:hover": {
			backgroundColor: alpha(theme.palette.error.main, 0.12),
			// Reset on mouse devices
			"@media (hover: none)": {
				backgroundColor: "transparent",
			},
		},
	},
}));

const RemoveFoldersButton = ({ selectedIds }) => {
	const translate = useTranslate();
	const [open, setOpen] = React.useState(false);
	const [localData, setLocalData] = React.useState({});
	const { data } = useListContext();
	const classes = useStyles();
	const [selectedValues, setSelectedValues] = React.useState({});
	const [updateMany, { loading: updateLoading }] = useUpdateMany();
	const unselectAll = useUnselectAll("messages");
	const refresh = useRefresh();
	const botIds = Object.keys(localData)
		.map((key) => ({
			id: localData[key].id,
			bot_id: localData[key].bot_id,
		}))
		.filter((m) => selectedIds.includes(m.id))
		.map((m) => m.bot_id);
	const uniqueBotIds = [...new Set(botIds)];
	const folderIds = Object.keys(selectedValues)
		.filter((_id) => selectedValues[_id] === true)
		.map((_id) => ({
			id: _id,
		}));

	const { data: folders, loading } = useGetList(
		"folders",
		{ page: 1, perPage: 10 },
		{ field: "name", order: "ASC" },
		{ bot_ids: uniqueBotIds?.join(",") }
	);

	useEffect(() => {
		setLocalData((d) => ({ ...d, ...data }));
	}, [data]);

	const handleUpdate = () => {
		updateMany(
			"messages",
			selectedIds,
			{
				remove_folders: folderIds,
			},
			{
				onSuccess: () => {
					setOpen(false);
					unselectAll();
					refresh();
				},
				onFailure: () => {
					setOpen(false);
					unselectAll();
					// refresh();
				},
			}
		);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
	};

	if (selectedIds?.length === 0 || selectedIds?.length === 1) {
		return null;
	}

	return (
		<>
			<Button
				className={classes.button}
				onClick={handleClickOpen}
				label={translate("resources.messages.actions.remove_folders")}
				disabled={loading || uniqueBotIds.length > 1}
			>
				<FolderIcon />
			</Button>
			<Dialog
				onClose={handleClose}
				aria-labelledby="simple-dialog-title"
				open={open}
			>
				{/* <DialogTitle id="simple-dialog-title">
					{translate("resources.messages.actions.select_folders")}
				</DialogTitle> */}
				<List>
					{Object.values(folders).map((folder) => (
						<ListItem
							button
							onClick={() => {
								setSelectedValues((prev) => ({
									...prev,
									[folder.id]: prev[folder.id] === undefined ? true : undefined,
								}));
							}}
							key={folder.id}
							selected={selectedValues?.[folder.id] === true}
						>
							<ListItemAvatar>
								<Avatar
									style={{ backgroundColor: folder?.color }}
									className={classes.avatar}
								>
									<FiberManualRecordIcon style={{ color: folder?.color }} />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={folder?.name} />
							<ListItemSecondaryAction>
								<Checkbox
									edge="end"
									onChange={() => {
										setSelectedValues((prev) => ({
											...prev,
											[folder.id]:
												prev[folder.id] === undefined ? true : undefined,
										}));
									}}
									checked={selectedValues?.[folder.id] === true}
								/>
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</List>
				<DialogActions>
					<Button
						onClick={handleClose}
						disabled={updateLoading}
						label={translate("ra.action.undo")}
						color="default"
					>
						<UndoIcon />
					</Button>
					<Button
						className={classes.button}
						onClick={handleUpdate}
						disabled={updateLoading || folderIds?.length === 0}
						label={translate("resources.messages.actions.remove_folders")}
					>
						<DoneIcon />
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default RemoveFoldersButton;
