import React from 'react';

import { get } from 'lodash';
import RemoteErrorsInteceptor, { withErrors } from 'ra-remote-validator';
import {
  AutocompleteArrayInput,
  BooleanInput,
  CheckboxGroupInput,
  FormDataConsumer,
  NumberInput,
  ReferenceArrayInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';
import { compose } from 'recompose';

import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import { DateTimeInput } from '../components/DatePicker';
import Create from '../components/form/Create';
import BotInput from '../components/inputs/BotInput';
import QueueCreateToolbar from './CreateToolbar';

const frequencyTypes = [
	{ id: "minutes", name: "resources.queues.fields.frequency_types.minutes" },
	{ id: "hours", name: "resources.queues.fields.frequency_types.hours" },
	{ id: "days", name: "resources.queues.fields.frequency_types.days" },
	{ id: "weeks", name: "resources.queues.fields.frequency_types.weeks" },
	{ id: "months", name: "resources.queues.fields.frequency_types.months" },
	{ id: "years", name: "resources.queues.fields.frequency_types.years" },
];

const deleteFrequencyTypes = [
	{ id: "minutes", name: "resources.queues.fields.frequency_types.minutes" },
	{ id: "hours", name: "resources.queues.fields.frequency_types.hours" },
];

const Form = ({ resource, translate, ...props }) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const { className, ...validProps } = props;
	return (
		<Grid container spacing={isSmall ? 0 : 1}>
			<Grid item xs={12} md={12}>
				<BotInput
					{...validProps}
					source="bot_id"
					validate={required()}
					resource={resource}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<TextInput
					{...validProps}
					source="code"
					resource={resource}
					fullWidth
				/>
			</Grid>
			<FormDataConsumer>
				{({ formData, ...rest }) => {
					if (!formData.bot_id) {
						return null;
					}
					return (
						<React.Fragment>
							<Grid item xs={12} md={12}>
								<ReferenceArrayInput
									{...validProps}
									source="messages"
									reference="messages"
									validate={required()}
									sort={{ field: "name", order: "ASC" }}
									filter={{ bot_id: formData.bot_id }}
									perPage={10}
									resource={resource}
									fullWidth
									{...rest}
								>
									<AutocompleteArrayInput
										validate={required()}
										optionText="name"
										fullWidth
										{...rest}
									/>
								</ReferenceArrayInput>
							</Grid>
							<Grid item xs={12}>
								<BooleanInput
									{...validProps}
									source="send_all"
									label="resources.queues.fields.send_all"
									defaultValue={false}
									resource={resource}
									fullWidth
									{...rest}
								/>
							</Grid>
							{formData.send_all ? (
								<React.Fragment>
									<Grid item xs={12} md={12}>
										<CheckboxGroupInput
											{...validProps}
											source="target_types"
											label="resources.queues.fields.target_types"
											validate={required()}
											choices={[
												{
													id: "private",
													name: "resources.targets.fields.types.private",
												},
												{
													id: "channel",
													name: "resources.targets.fields.types.channel",
												},
												{
													id: "group",
													name: "resources.targets.fields.types.group",
												},
												{
													id: "supergroup",
													name: "resources.targets.fields.types.supergroup",
												},
												{
													id: "unlockers",
													name: "resources.targets.fields.types.unlockers",
												},
												{
													id: "ununlockers",
													name: "resources.targets.fields.types.ununlockers",
												},
											]}
											{...rest}
										/>
									</Grid>
									{((formData?.target_types || []).includes("unlockers") ||
										(formData?.target_types || []).includes("ununlockers")) && (
										<Grid item xs={12} md={12}>
											<ReferenceArrayInput
												{...validProps}
												source="unlock_ids"
												reference="unlocks"
												sort={{ field: "name", order: "ASC" }}
												fullWidth
												isRequired={true}
												validate={required()}
												filter={{
													bot_id: formData.bot_id,
												}}
												label={translate("resources.unlocks.name", 2)}
												perPage={10}
												{...rest}
											>
												<AutocompleteArrayInput
													optionText="name"
													fullWidth
													{...rest}
												/>
											</ReferenceArrayInput>
										</Grid>
									)}
									<Grid item xs={12} md={12}>
										<ReferenceArrayInput
											{...validProps}
											source="targets_excluded"
											reference="targets"
											sort={{ field: "name", order: "ASC" }}
											filter={{
												bot_id: formData.bot_id,
												types: get(formData, "target_types", []).join(","),
											}}
											perPage={10}
											resource={resource}
											fullWidth
											{...rest}
										>
											<AutocompleteArrayInput
												optionText="name"
												fullWidth
												{...rest}
											/>
										</ReferenceArrayInput>
									</Grid>
								</React.Fragment>
							) : (
								<React.Fragment>
									<Grid item xs={12} md={12}>
										<ReferenceArrayInput
											{...validProps}
											source="targets"
											reference="targets"
											validate={required()}
											sort={{ field: "name", order: "ASC" }}
											filter={{ bot_id: formData.bot_id }}
											perPage={10}
											resource={resource}
											fullWidth
											{...rest}
										>
											<AutocompleteArrayInput
												validate={required()}
												optionText="display_name"
												fullWidth
												{...rest}
											/>
										</ReferenceArrayInput>
									</Grid>
								</React.Fragment>
							)}
							<Grid item xs={12} md={12}>
								<BooleanInput
									{...validProps}
									source="repeat"
									defaultValue={false}
									resource={resource}
									fullWidth
									{...rest}
								/>
							</Grid>
							<FormDataConsumer {...rest}>
								{({ formData, ...rest }) => {
									const { repeat } = formData;
									if (!repeat) {
										return (
											<Grid item xs={12} md={12}>
												<DateTimeInput
													{...validProps}
													source="send_date"
													resource={resource}
													fullWidth
													disablePast={true}
													{...rest}
												/>
												{formData.send_date &&
													formData.send_date < new Date() && (
														<Alert severity="error">
															{translate(
																"resources.queues.fields.send_date.alert"
															)}
														</Alert>
													)}
											</Grid>
										);
									}
									return (
										<React.Fragment>
											<Grid item xs={12} md={6}>
												<NumberInput
													{...validProps}
													source="frequency"
													validate={required()}
													resource={resource}
													fullWidth
													{...rest}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<SelectInput
													{...validProps}
													{...rest}
													source="frequency_type"
													validate={required()}
													resource={resource}
													fullWidth
													choices={frequencyTypes.map((ft) => {
														return {
															...ft,
															name: translate(ft.name, formData.frequency || 0),
														};
													})}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<DateTimeInput
													{...validProps}
													{...rest}
													resource={resource}
													fullWidth
													source="start_date"
													validate={required()}
													disablePast={true}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<DateTimeInput
													{...validProps}
													resource={resource}
													fullWidth
													source="end_date"
													validate={required()}
													disablePast={true}
													{...rest}
												/>
											</Grid>
										</React.Fragment>
									);
								}}
							</FormDataConsumer>
							<Grid item xs={12} md={12}>
								<BooleanInput
									{...validProps}
									source="pin_to_top_after"
									defaultValue={false}
									resource={resource}
									fullWidth
									helperText="resources.queues.fields.pin_to_top_after.help"
									{...rest}
								/>
							</Grid>
							<FormDataConsumer {...rest}>
								{({ formData, ...rest }) => {
									const { pin_to_top_after } = formData;
									if (!pin_to_top_after) {
										return null;
									}
									return (
										<React.Fragment>
											<Grid item xs={12} md={6}>
												<NumberInput
													{...validProps}
													source="pin_to_top_after_frequency"
													validate={required()}
													resource={resource}
													defaultValue={1}
													fullWidth
													{...rest}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<SelectInput
													{...validProps}
													{...rest}
													source="pin_to_top_after_frequency_type"
													validate={required()}
													resource={resource}
													fullWidth
													defaultValue="hours"
													choices={deleteFrequencyTypes.map((ft) => {
														return {
															...ft,
															name: translate(ft.name, formData.frequency || 0),
														};
													})}
												/>
											</Grid>
										</React.Fragment>
									);
								}}
							</FormDataConsumer>
							<Grid item xs={12} md={12}>
								<BooleanInput
									{...validProps}
									source="delete_after"
									defaultValue={false}
									resource={resource}
									fullWidth
									helperText="resources.queues.fields.delete_after.help"
									{...rest}
								/>
							</Grid>
							<FormDataConsumer {...rest}>
								{({ formData, ...rest }) => {
									const { delete_after } = formData;
									if (!delete_after) {
										return null;
									}
									return (
										<React.Fragment>
											<Grid item xs={12} md={6}>
												<NumberInput
													{...validProps}
													source="delete_after_frequency"
													validate={required()}
													resource={resource}
													fullWidth
													defaultValue={3}
													{...rest}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<SelectInput
													{...validProps}
													{...rest}
													source="delete_after_frequency_type"
													validate={required()}
													resource={resource}
													fullWidth
													choices={deleteFrequencyTypes.map((ft) => {
														return {
															...ft,
															name: translate(ft.name, formData.frequency || 0),
														};
													})}
													defaultValue="hours"
												/>
											</Grid>
										</React.Fragment>
									);
								}}
							</FormDataConsumer>
						</React.Fragment>
					);
				}}
			</FormDataConsumer>
		</Grid>
	);
};

const QueueCreate = ({ dispatch, validate, errors, ...props }) => {
	const translate = useTranslate();
	return (
		<Create {...props}>
			<SimpleForm
				variant="outlined"
				redirect="list"
				validate={validate}
				toolbar={<QueueCreateToolbar />}
			>
				<RemoteErrorsInteceptor errors={errors} dispatch={dispatch} />
				<Form translate={translate} />
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(QueueCreate);
