import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { get } from "lodash";
import React from "react";
import {
	Button,
	DeleteButton,
	SaveButton,
	Toolbar as ReactAdminToolbar,
	useTranslate,
} from "react-admin";

const useStyles = makeStyles((theme) => ({
	toolbar: {
		paddingLeft: theme.spacing(2),
		justifyContent: "space-between",
		display: "flex",
	},
	button: {
		marginRight: theme.spacing(1),
		overflow: "hidden",
	},
}));

const Toolbar = ({ mutationMode, validating, ...props }) => {
	const translate = useTranslate();
	const classes = useStyles();
	const { record, saving } = props;
	const previewUrl = `${get(
		record,
		"preview_url"
	)}?token=${localStorage.getItem("token")}`;
	const messagesUrl = `/messages?filter=${JSON.stringify({
		import_queue_id: get(record, "id"),
	})}`;

	return (
		<ReactAdminToolbar className={classes.toolbar} {...props}>
			<SaveButton
				className={classes.button}
				redirect="edit"
				submitOnEnter={true}
				disabled={props.pristine}
			/>
			<Button
				disabled={saving}
				className={classes.button}
				variant="text"
				color="primary"
				label={translate(`resources.import_queues.actions.save_preview`)}
				onClick={() => window.open(previewUrl)}
			>
				<VisibilityIcon />
			</Button>
			<SaveButton
				{...props}
				disabled={saving}
				className={classes.button}
				submitOnEnter={false}
				variant="text"
				color="primary"
				size="small"
				label={translate(`resources.import_queues.actions.save_show_messages`)}
				redirect={messagesUrl}
			>
				<SendIcon />
			</SaveButton>
			<DeleteButton undoable={false} />
		</ReactAdminToolbar>
	);
};

export default Toolbar;
