const convertFileToBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file.rawFile);

		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});

const convertOneOrMany = (data, propertyName) => {
	if (data && data[propertyName]) {
		if (
			data[propertyName].rawFile &&
			data[propertyName].rawFile instanceof File
		) {
			return convertFileToBase64(data[propertyName]).then((convertedFile) => ({
				data: convertedFile,
				name: data[propertyName].rawFile.name,
				size: data[propertyName].rawFile.size,
				type: data[propertyName].rawFile.type,
			}));
		}
		if (data[propertyName].length && data[propertyName].length > 0) {
			return Promise.all(
				data[propertyName].map((file) =>
					file.rawFile
						? convertFileToBase64(file).then((convertedFile) => ({
								data: convertedFile,
								name: file.rawFile.name,
								size: file.rawFile.size,
								type: file.rawFile.type,
						  }))
						: file
				)
			);
		}
	}
	return Promise.resolve(data[propertyName]);
};

const MANY_PROPS = [];
const ONE_PROPS = [
	"value_single_media",
	"value_multiple_media",
	"template_media",
];

async function convertDocs(data) {
	if (data === undefined || data === null) {
		return;
	}
	for (var manyIndex = 0; manyIndex < MANY_PROPS.length; manyIndex++) {
		let array = data[MANY_PROPS[manyIndex]];
		if (array === undefined) {
			continue;
		}
	}
	for (var oneIndex = 0; oneIndex < ONE_PROPS.length; oneIndex++) {
		let one = data[ONE_PROPS[oneIndex]];

		if (one === undefined) {
			continue;
		}
		data[ONE_PROPS[oneIndex]] = await convertOneOrMany(
			data,
			ONE_PROPS[oneIndex]
		);
	}
	let otherArrays = Object.keys(data).filter(
		(k) =>
			data[k] !== undefined &&
			Array.isArray(data[k]) &&
			MANY_PROPS.indexOf(k) === -1 &&
			ONE_PROPS.indexOf(k) === -1
	);

	for (var arrayIndex = 0; arrayIndex < otherArrays.length; arrayIndex++) {
		for (var i = 0; i < data[otherArrays[arrayIndex]].length; i++) {
			data[otherArrays[arrayIndex]][i] = await convertDocs(
				data[otherArrays[arrayIndex]][i]
			);
		}
	}
	return data;
}

const addUploadFeature = (requestHandler) => (type, resource, params) => {
	return convertDocs(params.data).then((data) =>
		requestHandler(type, resource, {
			...params,
			data,
		})
	);
};

export default addUploadFeature;
