import React from 'react';

import { get } from 'lodash';
import {
  useRedirect,
  useTranslate,
} from 'react-admin';

import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

const SendMessageButton = ({
	basePath = "",
	label = "resources.messages.buttons.send",
	record,
	...rest
}) => {
	const redirect = useRedirect();
	const translate = useTranslate();
	const handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const source = encodeURIComponent(
			JSON.stringify({
				bot_id: get(record, "bot_id"),
				messages: [get(record, "id")],
			})
		);
		redirect(`/queues/create?source=${source}`);
		// redirect(
		// 	"create",
		// 	"/queues",
		// 	null,
		// 	{},
		// 	{
		// 		record: {
		// 			bot_id: get(record, "bot_id"),
		// 			messages: [get(record, "id")],
		// 		},
		// 	}
		// );
	};

	return (
		<Tooltip title={translate(label)}>
			<IconButton color="secondary" onClick={handleClick} {...rest}>
				<SendIcon fontSize="small" />
			</IconButton>
		</Tooltip>
	);
};

export default SendMessageButton;
