import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { get } from 'lodash';
import { useTranslate } from 'react-admin';

const Bot = createContext({});

export const BotContext = ({ children }) => {
	const translate = useTranslate();
	const authProfile = JSON.parse(localStorage.getItem("profile"));
	const authProfileId = get(authProfile, "id");
	const storageName = `current_bots.${authProfileId}`;
	const initialBots = localStorage.getItem(storageName);
	const defaultState = initialBots ? JSON.parse(initialBots) : {};
	const [bots, setBots] = useState(defaultState);

	const botIds = useMemo(() => Object.keys(bots), [bots]);

	const isBotSelected = useCallback(
		(botId) => {
			const bot = get(bots, botId);
			return !!bot;
		},
		[bots]
	);

	const getSelectedBotNames = useCallback(
		(force = false) => {
			if (force) {
				return Object.values(bots)
					.map((bot) => bot.name)
					.join(", ");
			}

			if (Object.keys(bots).length === 0) {
				return translate("resources.bots.actions.select");
			} else if (Object.keys(bots).length === 1) {
				return Object.values(bots)[0].name;
			} else {
				return translate("resources.bots.actions.selected", {
					count: Object.keys(bots).length,
				});
			}
		},
		[bots, translate]
	);

	const canShowBotIdField = useCallback(() => botIds.length > 1, [botIds]);

	const toggleBot = useCallback(
		(_bot) => {
			const botExists = get(bots, _bot.id);

			if (botExists) {
				const { [_bot.id]: _, ..._bots } = bots;
				localStorage.setItem(storageName, JSON.stringify(_bots));
				setBots((b) => _bots);
			} else {
				localStorage.setItem(
					storageName,
					JSON.stringify({
						...bots,
						[_bot.id]: _bot,
					})
				);
				setBots((_bots) => ({
					..._bots,
					[_bot.id]: _bot,
				}));
			}
		},
		[bots, storageName]
	);

	const toggleAllBots = useCallback(
		(_bots) => {
			localStorage.setItem(storageName, JSON.stringify(_bots));
			setBots((b) => _bots);
		},
		[storageName]
	);

	const memoizedValue = useMemo(
		() => ({
			bots,
			toggleBot,
			toggleAllBots,
			isBotSelected,
			getSelectedBotNames,
			botIds,
			canShowBotIdField,
		}),
		[
			bots,
			toggleBot,
			isBotSelected,
			getSelectedBotNames,
			botIds,
			canShowBotIdField,
			toggleAllBots,
		]
	);

	return <Bot.Provider value={memoizedValue}>{children}</Bot.Provider>;
};

export const useBotContext = () => useContext(Bot);
