import React, { useMemo } from 'react';

import {
  Button,
  useListContext,
  useRedirect,
  useTranslate,
  useUnselectAll,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import { useBotContext } from '../../contexts/BotContext';

const SetAllBotsButton = ({ selectedIds }) => {
	const translate = useTranslate();
	const { toggleAllBots, bots } = useBotContext();
	const { data } = useListContext();

	const { search } = useLocation();
	const resource = new URLSearchParams(search).get("back");
	const redirect = useRedirect();
	const unselectAll = useUnselectAll("bots");

	const selectedData = useMemo(() => {
		if (!data || !selectedIds) {
			return [];
		}
		const selected = Object.values(data).filter((item) =>
			selectedIds.includes(item.id)
		);
		const selectedBots = selected.reduce((acc, item) => {
			acc[item.id] = item;
			return acc;
		}, {});
		return {
			...bots,
			...selectedBots,
		};
	}, [data, selectedIds, bots]);

	const handleClick = () => {
		toggleAllBots(selectedData);
		unselectAll();
		if (resource && resource !== "bots") {
			redirect(resource);
		}
	};

	return (
		<Button
			label={translate("resources.bots.actions.select_this")}
			onClick={handleClick}
		/>
	);
};

export default SetAllBotsButton;
