import RemoteErrorsInteceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	ArrayInput,
	CheckboxGroupInput,
	required,
	SelectInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
} from "react-admin";
import { compose } from "recompose";
import Create from "../components/form/Create";
import { components, rules } from "./index";

const MessageTypeCreate = ({ dispatch, validate, errors, ...props }) => {
	return (
		<Create {...props}>
			<SimpleForm variant="outlined" redirect="list" validate={validate}>
				<RemoteErrorsInteceptor errors={errors} dispatch={dispatch} />
				<TextInput validate={required()} source="name" fullWidth />
				<TextInput validate={required()} source="method" fullWidth />
				<ArrayInput validate={required()} source="message_type_fields">
					<SimpleFormIterator
						disableReordering
						TransitionProps={{
							enter: false,
						}}
					>
						<TextInput source="name" fullWidth />
						<TextInput source="default" fullWidth />
						<CheckboxGroupInput source="rules" choices={rules} fullWidth />
						<SelectInput source="component" choices={components} fullWidth />
					</SimpleFormIterator>
				</ArrayInput>
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(MessageTypeCreate);
