import * as React from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {
  ImageField as RaImageField,
  sanitizeFieldRestProps,
  useRecordContext,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(
	{
		list: {
			display: "flex",
			listStyleType: "none",
		},
		image: {
			margin: "0.5rem",
			maxHeight: "10rem",
		},
	},
	{ name: "RaImageField" }
);

const ImageField = (props: ImageFieldProps) => {
	const {
		className,
		classes: classesOverride,
		emptyText,
		source,
		src,
		title,
		...rest
	} = props;
	const record = useRecordContext(props);
	const sourceValue = get(record, source);
	const classes = useStyles(props);
	if (!sourceValue) {
		return emptyText ? (
			<Typography
				component="span"
				variant="body2"
				className={className}
				{...sanitizeFieldRestProps(rest)}
			>
				{emptyText}
			</Typography>
		) : (
			<div className={className} {...sanitizeFieldRestProps(rest)} />
		);
	}

	if (Array.isArray(sourceValue)) {
		return (
			<ul
				className={classnames(classes.list, className)}
				{...sanitizeFieldRestProps(rest)}
			>
				{sourceValue.map((file, index) => {
					const fileTitleValue = get(file, title) || title;
					const srcValue = get(file, src) || title;

					return (
						<li key={index}>
							<a
								href={srcValue}
								title={fileTitleValue}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									alt={fileTitleValue}
									title={fileTitleValue}
									src={srcValue}
									className={classes.image}
								/>
							</a>
						</li>
					);
				})}
			</ul>
		);
	}

	const titleValue = get(record, title) || title;

	return (
		<div className={className} {...sanitizeFieldRestProps(rest)}>
			<a
				href={sourceValue}
				title={titleValue}
				target="_blank"
				rel="noopener noreferrer"
			>
				<img
					title={titleValue}
					alt={titleValue}
					src={sourceValue}
					className={classes.image}
				/>
			</a>
		</div>
	);
};

// What? TypeScript loses the displayName if we don't set it explicitly
ImageField.displayName = "ImageField";

ImageField.defaultProps = {
	addLabel: true,
};

ImageField.propTypes = {
	...RaImageField.propTypes,
	src: PropTypes.string,
	title: PropTypes.string,
};

export default ImageField;
