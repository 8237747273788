import React from "react";
import { BulkDeleteButton } from "react-admin";
import ReplyButton from "./inputs/ReplyButton";
import SendMessagesButton from "./inputs/SendMessagesButton";

const ReplyBulkActionButtons = (props) => (
	<React.Fragment>
		<ReplyButton {...props} />
		<SendMessagesButton {...props} />
		{/* default bulk delete action */}
		<BulkDeleteButton {...props} />
	</React.Fragment>
);

export default ReplyBulkActionButtons;
