import { makeStyles } from "@material-ui/styles";
import React from "react";
import { List as RaList, Pagination } from "react-admin";
import { useListStyles } from "../../hooks/useStyles";
import perPage from "../../utils/perPage";
import ListActions from "./ListActions";

const useStyles = makeStyles((theme) => ({
	actions: {
		whiteSpace: "nowrap",
	},
}));

const List = ({ createLabel = null, children, ...props }) => {
	const classes = useListStyles();
	const inlineClasses = useStyles();
	return (
		<RaList
			pagination={
				<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 250, 500]} />
			}
			classes={classes}
			perPage={perPage()}
			exporter={false}
			actions={
				<ListActions
					createLabel={createLabel || "ra.action.add"}
					className={inlineClasses.actions}
				/>
			}
			{...props}
		>
			{children}
		</RaList>
	);
};

export default List;
