import React from 'react';

import {
  CheckboxGroupInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
} from 'react-admin';
import {
  useForm,
  useFormState,
} from 'react-final-form';

import {
  Grid,
  InputAdornment,
  useMediaQuery,
} from '@material-ui/core';

import BotInput from '../components/inputs/BotInput';
import MessageTypesInput from './inputs/MessageTypesInput';

const Form = ({ create, ...props }) => {
	const form = useForm();
	const formState = useFormState();
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const { className, ...validProps } = props;
	const clone = validProps?.record?.clone;
	const botId = formState?.values?.bot_id;

	return (
		<Grid container spacing={isSmall ? 0 : 1}>
			<Grid item xs={12} md={6}>
				<BotInput
					{...validProps}
					source="bot_id"
					fullWidth
					disabled={!create}
					onChange={() => {
						if (create) {
							form.change("folders", null);
						}
					}}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<ReferenceInput
					{...validProps}
					source="message_type_id"
					reference="message_types"
					fullWidth
					sort={{ field: "name", order: "ASC" }}
					onChange={() => form.change("messages_message_type_fields", null)}
				>
					<SelectInput disabled={!create || clone} optionText="name" />
				</ReferenceInput>
			</Grid>
			<Grid item xs={12} md={12}>
				<TextInput
					{...validProps}
					validate={required()}
					source="name"
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<TextInput
					{...validProps}
					source="command"
					fullWidth
					InputProps={{
						startAdornment: <InputAdornment position="start">/</InputAdornment>,
					}}
				/>
			</Grid>
			<Grid item xs={12} md={12} key={botId}>
				<ReferenceArrayInput
					{...validProps}
					source="folders"
					label="resources.messages.fields.folder_ids"
					reference="folders"
					fullWidth
					parse={(value) => value && value.map((v) => ({ id: v }))}
					format={(value) => value && value.map((v) => v.id)}
					filter={{ bot_ids: [botId] }}
				>
					<CheckboxGroupInput optionText="name" />
				</ReferenceArrayInput>
			</Grid>
			<Grid item xs={12} md={12}>
				<MessageTypesInput {...validProps} source="message_type_id" />
			</Grid>
		</Grid>
	);
};

export default Form;
