import React from "react";
import { BulkDeleteButton } from "react-admin";
import MarkAsSentButton from "./MarkAsSentButton";

const BulkActionButtons = (props) => (
	<React.Fragment>
		<MarkAsSentButton {...props} />
		{/* default bulk delete action */}
		<BulkDeleteButton {...props} />
	</React.Fragment>
);

export default BulkActionButtons;
