import { withErrors } from "ra-remote-validator";
import React from "react";
import { compose } from "recompose";
import Create from "../components/form/Create";
import Form from "./Form";

const ImportQueueCreate = ({ dispatch, validate, errors, ...props }) => (
	<Create {...props}>
		<Form
			validate={validate}
			errors={errors}
			dispatch={dispatch}
			create={true}
		/>
	</Create>
);

export default compose(withErrors)(ImportQueueCreate);
