import {
	Drawer,
	IconButton,
	makeStyles,
	useMediaQuery,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import classnames from "classnames";
import PropTypes from "prop-types";
import { toggleSidebar } from "ra-core";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import logo from "./assets/img/logo.png";

const useStyles = makeStyles((theme) => ({
	brand: {
		paddingLeft: theme.spacing(1),
		display: "flex",
		flexGrow: 1,
		flex: 1,
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "center",
		boxShadow: "none",
	},
	logo: {
		maxWidth: "100%",
		height: "50px",
	},
	title: {
		fontWeight: "bold",
		color: theme.palette.text.primary,
		marginBottom: theme.spacing(-0.5),
		"&:hover": {
			color: theme.palette.primary.main,
		},
	},
	drawer: {
		zIndex: 1,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: ({ drawerWidth }) => ({
		width: drawerWidth - 1,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up("md")]: {
			width: theme.spacing(7) + 1,
		},
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
	},
	navigation: {
		"& nav": {
			boxShadow: "none",
			"& .MuiList-subheader": {
				paddingBottom: 0,
				"& li": {
					textTransform: "uppercase",
				},
			},
		},
		"& .MuiListItemIcon-root": {
			minWidth: theme.spacing(5),
		},
	},
}));
const Sidebar = ({
	children,
	open,
	drawerWidth,
	appTitle,
	appSubTitle,
	appVersion,
}) => {
	const classes = useStyles({ drawerWidth });
	const dispatch = useDispatch();
	const handleToggleSidebar = useCallback(
		() => dispatch(toggleSidebar()),
		[dispatch]
	);
	const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

	return (
		<Drawer
			elevation={0}
			open={open}
			onClose={handleToggleSidebar}
			variant={isXSmall ? "temporary" : "permanent"}
			className={classnames(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			})}
			classes={{
				paper: classnames({
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}
		>
			<div className={classes.toolbar}>
				<div className={classes.brand}>
					<img src={logo} alt={appTitle} className={classes.logo} />
				</div>
				<IconButton onClick={handleToggleSidebar}>
					<ChevronLeftIcon />
				</IconButton>
			</div>
			<div className={classes.navigation}>
				{React.Children.map(children, (child) =>
					React.cloneElement(child, {
						onMenuClick: isXSmall ? handleToggleSidebar : undefined,
					})
				)}
			</div>
		</Drawer>
	);
};

Sidebar.propTypes = {
	children: PropTypes.node,
	open: PropTypes.bool.isRequired,
	config: PropTypes.object,
	drawerWidth: PropTypes.number.isRequired,
	appName: PropTypes.string,
	appSubTitle: PropTypes.string,
	appVersion: PropTypes.string,
};

export default Sidebar;
