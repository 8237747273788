import React from 'react';

import { Button } from 'react-admin';

// import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

import { useBotContext } from '../../contexts/BotContext';
import { AndroidIcon } from '../../icons';

const useStyles = makeStyles((theme) => ({
	unselect: {
		color: red[500],
	},
}));

const SetBotButton = ({ record }) => {
	const classes = useStyles();
	const { isBotSelected, toggleBot } = useBotContext();
	// const { search } = useLocation();
	// const resource = new URLSearchParams(search).get("back");
	// const redirect = useRedirect();

	const handleClick = () => {
		toggleBot(record);
		// if (resource && resource !== "bots") {
		// 	redirect(resource);
		// }
	};

	if (isBotSelected(record.id)) {
		return (
			<Button
				className={classes.unselect}
				label="resources.bots.actions.unselect_this"
				onClick={handleClick}
			>
				<AndroidIcon />
			</Button>
		);
	}

	return (
		<Button label="resources.bots.actions.select_this" onClick={handleClick}>
			<AndroidIcon />
		</Button>
	);
};

export default SetBotButton;
