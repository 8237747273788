import React from 'react';

import {
  ChipField,
  DateField,
  EditButton,
  TextField,
} from 'react-admin';

import { useMediaQuery } from '@material-ui/core';

import Datagrid from '../components/lists/Datagrid';
import ListBot from '../components/lists/ListBot';
import { useBotContext } from '../contexts/BotContext';
import Filters from './Filters';
import { TypeField } from './Type';

const ListItems = (props) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const { canShowBotIdField } = useBotContext();
	return (
		<Datagrid {...props} size="small" showEdit={false}>
			<TextField source="name" style={{ fontWeight: "bold" }} />
			{!isSmall && <TextField source="telegram_username" />}
			{!isSmall && <TextField source="telegram_fullname" />}
			<TypeField source="type" />
			{canShowBotIdField() && <ChipField source="bot.name" label="Bot" />}
			<DateField source="created" showTime />
			<EditButton />
		</Datagrid>
	);
};

const TargetList = (props) => (
	<ListBot
		{...props}
		sort={{ field: "created", order: "DESC" }}
		filters={<Filters />}
	>
		<ListItems />
	</ListBot>
);
export default TargetList;
