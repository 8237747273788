import React from 'react';

import RemoteErrorsInterceptor, { withErrors } from 'ra-remote-validator';
import {
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';
import { compose } from 'recompose';

import Edit from '../components/form/Edit';
import ClipboardTextInput from '../components/inputs/ClipboardTextInput';
import ApiKeyInput from './inputs/ApiKeyInput';
import StatusField from './StatusField';
import Toolbar from './Toolbar';

const BotEdit = ({ dispatch, validate, errors, ...props }) => {
	const translate = useTranslate();
	return (
		<Edit title="username" titlePrefix="@" {...props}>
			<SimpleForm variant="outlined" validate={validate} toolbar={<Toolbar />}>
				<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
				<StatusField source="status" />
				<TextInput validate={required()} source="name" fullWidth />
				<ClipboardTextInput validate={required()} source="username" fullWidth />
				<ApiKeyInput validate={required()} source="api_key" fullWidth />
				<FormDataConsumer>
					{({ formData, ...rest }) => (
						<React.Fragment>
							<ReferenceInput
								{...rest}
								className=""
								source="message_id"
								reference="messages"
								sort={{ field: "name", order: "ASC" }}
								filter={{ bot_id: formData.id }}
								perPage={10}
								allowEmpty
								emptyText={translate("app.action.none")}
								fullWidth
							>
								<AutocompleteInput optionText="name" />
							</ReferenceInput>
							<ReferenceInput
								{...rest}
								className=""
								source="unlock_id"
								label={translate("resources.unlocks.name", 1)}
								reference="unlocks"
								sort={{ field: "name", order: "ASC" }}
								filter={{ bot_id: formData.id }}
								perPage={10}
								allowEmpty
								emptyText={translate("app.action.none")}
								fullWidth
							>
								<AutocompleteInput optionText="name" />
							</ReferenceInput>
						</React.Fragment>
					)}
				</FormDataConsumer>
			</SimpleForm>
		</Edit>
	);
};
export default compose(withErrors)(BotEdit);
