import React from 'react';

import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

import Filters from '../components/form/Filters';

const MessageFilters = (props) => (
	<Filters {...props}>
		<SelectInput
			source="pin_to_top"
			choices={[
				{ id: 1, name: "ra.boolean.yes" },
				{ id: 0, name: "ra.boolean.no" },
			]}
			emptyText="ra.boolean.all"
			alwayOn
		/>
		<ReferenceInput source="import_queue_id" reference="import_queues">
			<AutocompleteInput
				optionText="name"
				margin="dense"
				style={{ minWidth: 200 }}
			/>
		</ReferenceInput>
	</Filters>
);
export default MessageFilters;
