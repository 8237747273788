import { withErrors } from "ra-remote-validator";
import React from "react";
import { Create as RaCreate } from "react-admin";
import { compose } from "recompose";
import { useFormStyles } from "../../hooks/useStyles";

const Create = ({ dispatch, validate, children, ...props }) => {
	const classes = useFormStyles();
	return (
		<RaCreate classes={classes} redirect="list" variant="outlined" {...props}>
			{children}
		</RaCreate>
	);
};

export default compose(withErrors)(Create);
