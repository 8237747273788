import React, { Fragment } from 'react';

import { get } from 'lodash';
import {
  AutocompleteInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useTranslate,
} from 'react-admin';

import {
  Grid,
  useMediaQuery,
} from '@material-ui/core';

import BotInput from '../components/inputs/BotInput';
import * as Validation from '../utils/validation';
import actionTypes from './action_types';

const optionalUrl = () => (value) => {
	if (value === "") return undefined;
	let valid = Validation.url(value);
	return valid === true ? undefined : valid;
};

const Form = ({
	create = false,
	dispatch,
	validate,
	errors,
	permissions,
	...props
}) => {
	const translate = useTranslate();
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const { className, ...validProps } = props;

	return (
		<Grid container spacing={isSmall ? 0 : 1}>
			<Grid item xs={12} md={6}>
				<BotInput
					{...validProps}
					source="bot_id"
					validate={required()}
					fullWidth
				/>
			</Grid>
			<FormDataConsumer>
				{({ formData, ...rest }) => {
					if (!formData.bot_id) {
						return null;
					}
					const actionType = get(formData, "action_type", "clicks");
					const actions = get(formData, "actions", 0);
					return (
						<Fragment>
							<Grid item xs={12} />
							<Grid item xs={12} md={6}>
								<TextInput
									{...validProps}
									validate={required()}
									source="name"
									helperText="resources.unlocks.helpers.name"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextInput
									{...validProps}
									validate={required()}
									source="command"
									helperText="resources.unlocks.helpers.command"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<NumberInput
									{...validProps}
									source="actions"
									helperText="resources.unlocks.helpers.actions"
									defaultValue={3}
									validate={required()}
								/>
							</Grid>
							<Grid item xs={12} md={8}>
								<SelectInput
									{...validProps}
									source="action_type"
									choices={actionTypes}
									defaultValue="clicks"
									helperText={translate(
										`resources.unlocks.helpers.action_type.${actionType}`,
										{ actions }
									)}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<TextInput
									{...validProps}
									source="url"
									validate={[
										required(),
										actionType === "telegram" ? null : optionalUrl(),
									]}
									fullWidth
									helperText={translate(
										`resources.unlocks.helpers.url.${actionType}`,
										{ actions }
									)}
									label={translate(
										`resources.unlocks.fields.url.${actionType}`,
										{ actions }
									)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<ReferenceInput
									{...validProps}
									source="start_message_id"
									reference="messages"
									validate={required()}
									filter={{ bot_id: formData.bot_id }}
									fullWidth
								>
									<AutocompleteInput
										optionText="name"
										helperText="resources.unlocks.helpers.start_message_id"
									/>
								</ReferenceInput>
							</Grid>
							<Grid item xs={12} md={6}>
								<ReferenceInput
									{...validProps}
									source="result_message_id"
									reference="messages"
									validate={required()}
									filter={{ bot_id: formData.bot_id }}
									fullWidth
								>
									<AutocompleteInput
										optionText="name"
										helperText="resources.unlocks.helpers.result_message_id"
									/>
								</ReferenceInput>
							</Grid>
						</Fragment>
					);
				}}
			</FormDataConsumer>
		</Grid>
	);
};

export default Form;
