import React from "react";
import {
	AutocompleteArrayInput,
	ReferenceArrayInput,
	TextInput,
} from "react-admin";
import { DateTimeInput } from "../components/DatePicker";
import Filters from "../components/form/Filters";
import { StatusInput } from "./Status";

const QueueFilters = (props) => (
	<Filters {...props}>
		<DateTimeInput source="send_date_start" alwaysOn />
		<DateTimeInput source="send_date_end" alwaysOn />
		<StatusInput source="status" />
		<ReferenceArrayInput source="target_ids" reference="targets">
			<AutocompleteArrayInput optionText="name" />
		</ReferenceArrayInput>
		<ReferenceArrayInput source="message_ids" reference="messages">
			<AutocompleteArrayInput optionText="name" />
		</ReferenceArrayInput>
		<TextInput source="code" />
	</Filters>
);
export default QueueFilters;
