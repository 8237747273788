import React from 'react';

import RemoteErrorsInteceptor, { withErrors } from 'ra-remote-validator';
import {
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { compose } from 'recompose';

import Create from '../components/form/Create';
import ColorInput from '../components/inputs/ColorInput';

const FolderCreate = ({ dispatch, validate, errors, ...props }) => {
	return (
		<Create {...props}>
			<SimpleForm variant="outlined" redirect="list" validate={validate}>
				<RemoteErrorsInteceptor errors={errors} dispatch={dispatch} />
				<ReferenceInput
					source="bot_id"
					reference="bots"
					fullWidth
					allowEmpty={true}
					emptyText="ra.boolean.all"
				>
					<SelectInput optionText="name" />
				</ReferenceInput>
				<TextInput validate={required()} source="name" fullWidth />
				<ColorInput source="color" defaultValue="#000000" />
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(FolderCreate);
