import React from 'react';

import {
  ChipField,
  DateField,
  TextField,
} from 'react-admin';

import { useMediaQuery } from '@material-ui/core';

import Datagrid from '../components/lists/Datagrid';
import ListBot from '../components/lists/ListBot';
import { useBotContext } from '../contexts/BotContext';
import ReplyBulkActionButtons from './BulkActionButtons';
import Filters from './Filters';

const ListItems = (props) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const { canShowBotIdField } = useBotContext();
	return (
		<Datagrid {...props} showEdit={false}>
			<TextField
				source="data"
				variant="caption"
				color="textSecondary"
				style={{ whiteSpace: "pre-line", maxWidth: 350 }}
				component="div"
			/>
			<TextField
				source="telegram_from_username"
				style={{ fontWeight: "bold" }}
			/>
			{!isSmall && <TextField source="telegram_from_id" />}
			{canShowBotIdField() && <ChipField source="bot.name" label="Bot" />}
			<DateField source="created" showTime />
		</Datagrid>
	);
};

const ReplyList = (props) => (
	<ListBot
		{...props}
		sort={{ field: "created", order: "DESC" }}
		filters={<Filters />}
		bulkActionButtons={<ReplyBulkActionButtons />}
	>
		<ListItems />
	</ListBot>
);

export default ReplyList;
