import React, { useState } from "react";

import { get } from "lodash";
import { Button, useDataProvider, useRedirect } from "react-admin";

import FileCopyIcon from "@material-ui/icons/FileCopy";

const CloneButton = ({
	basePath = "",
	label = "ra.action.clone",
	record,
	...rest
}) => {
	const redirect = useRedirect();
	const dataProvider = useDataProvider();
	const [loading, setLoading] = useState(false);
	const clone = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setLoading(true);
		dataProvider.getOne("messages", { id: record.id }).then((response) => {
			const data = get(response, "data", {});
			const { id, created, modified, bot, command, ...record } = data;
			const source = encodeURIComponent(
				JSON.stringify({
					...record,
					name: `${record.name} copy`,
					clone: true,
				})
			);
			setLoading(false);
			redirect(`/messages/create?source=${source}`);
		});
	};

	return (
		<Button label={label} color="secondary" onClick={clone} disabled={loading}>
			<FileCopyIcon />
		</Button>
	);
};

export default CloneButton;
