import { Grid, useMediaQuery } from "@material-ui/core";
import { get } from "lodash";
import React, { Fragment } from "react";
import { ReferenceInput, SelectInput, TextInput } from "react-admin";
import { useFormState } from "react-final-form";
import ColorInput from "../../components/inputs/ColorInput";
import ImageInput from "../../components/inputs/ImageInput";

const TemplateInput = ({ source, ...props }) => {
	const form = useFormState();
	const values = get(form, "values", {});
	const template = get(values, source);
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	return (
		<Fragment>
			<ImageInput
				{...props}
				source={source}
				accept="image/*"
				multiple={false}
			/>
			{template && (
				<Grid container spacing={isSmall ? 0 : 1}>
					<Grid item xs={12} md={6}>
						<ColorInput
							source="bg_color"
							{...props}
							helperText="resources.import_queues.helpers.bg_color"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ColorInput
							source="color"
							{...props}
							helperText="resources.import_queues.helpers.color"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextInput
							source="image_coords"
							{...props}
							helperText="resources.import_queues.helpers.image_coords"
							defaultValue="0,0"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextInput
							source="image_size"
							{...props}
							helperText="resources.import_queues.helpers.image_size"
							defaultValue="800,400"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextInput
							source="price_coords"
							{...props}
							helperText="resources.import_queues.helpers.price_coords"
							defaultValue="0,0"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextInput
							source="price_size"
							{...props}
							helperText="resources.import_queues.helpers.price_size"
							defaultValue="800,200"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ColorInput
							source="price_color"
							{...props}
							helperText="resources.import_queues.helpers.price_color"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ReferenceInput
							source="price_font"
							reference="fonts"
							{...props}
							helperText="resources.import_queues.helpers.price_font"
						>
							<SelectInput />
						</ReferenceInput>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextInput
							source="price_original_coords"
							{...props}
							helperText="resources.import_queues.helpers.price_original_coords"
							defaultValue="0,0"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextInput
							source="price_original_size"
							{...props}
							helperText="resources.import_queues.helpers.price_original_size"
							defaultValue="800,100"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ColorInput
							source="price_original_color"
							{...props}
							helperText="resources.import_queues.helpers.price_original_color"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ReferenceInput
							source="price_original_font"
							reference="fonts"
							{...props}
							helperText="resources.import_queues.helpers.price_original_font"
						>
							<SelectInput />
						</ReferenceInput>
					</Grid>
				</Grid>
			)}
		</Fragment>
	);
};

export default TemplateInput;
