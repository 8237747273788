import React, { Fragment } from "react";

import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import {
	AutocompleteInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import { compose } from "recompose";

import { DateTimeInput } from "../components/DatePicker";
import Edit from "../components/form/Edit";
import { StatusInput } from "./Status";

const formatResponse = (v) => {
	try {
		return JSON.stringify(JSON.parse(v), null, 2);
	} catch (error) {
		return "";
	}
};

const Form = ({ className, ...props }) => {
	return (
		<Fragment>
			<TextInput {...props} disabled source="code" fullWidth />
			<ReferenceInput
				{...props}
				source="bot_id"
				reference="bots"
				disabled
				fullWidth
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
			<ReferenceInput
				{...props}
				fullWidth
				source="message_id"
				reference="messages"
				validate={required()}
				disabled
			>
				<AutocompleteInput validate={required()} optionText="name" />
			</ReferenceInput>
			<ReferenceInput
				{...props}
				source="target_id"
				reference="targets"
				fullWidth
				validate={required()}
				disabled
			>
				<AutocompleteInput validate={required()} optionText="name" />
			</ReferenceInput>
			<StatusInput {...props} source="status" validate={required()} fullWidth />
			<DateTimeInput
				{...props}
				source="send_date"
				validate={required()}
				fullWidth
			/>
			<DateTimeInput {...props} source="pin_to_top_date" fullWidth />
			<DateTimeInput {...props} source="delete_date" fullWidth />
			<TextInput
				{...props}
				multiline
				minRows={10}
				disabled
				fullWidth
				source="response"
				format={formatResponse}
			/>
		</Fragment>
	);
};

const QueueEdit = ({ dispatch, validate, errors, ...props }) => (
	<Edit {...props} title="code">
		<SimpleForm variant="outlined" validate={validate}>
			<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
			<Form />
		</SimpleForm>
	</Edit>
);
export default compose(withErrors)(QueueEdit);
