import React from 'react';

import {
  ChipField,
  DateField,
  TextField,
} from 'react-admin';

import { useMediaQuery } from '@material-ui/core';

import Datagrid from '../components/lists/Datagrid';
import ListBot from '../components/lists/ListBot';
import { useBotContext } from '../contexts/BotContext';
import CloneButton from './CloneButton';
import StatusField from './fields/StatusField';
import Filters from './Filters';

const ListItems = (props) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const { canShowBotIdField } = useBotContext();
	return (
		<Datagrid {...props}>
			<TextField source="name" style={{ fontWeight: "bold" }} />
			<StatusField source="status" />
			<TextField
				source="source.name"
				color="textSecondary"
				label="resources.import_queues.fields.source_id"
			/>
			{canShowBotIdField() && <ChipField source="bot.name" label="Bot" />}
			<DateField source="created" showTime />
			{!isSmall && <CloneButton />}
		</Datagrid>
	);
};

const ImportQueueList = (props) => (
	<ListBot
		{...props}
		sort={{ field: "created", order: "desc" }}
		filters={<Filters />}
	>
		<ListItems />
	</ListBot>
);

export default ImportQueueList;
